import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "./contexts";
import { Home } from "./pages/home";
import { Signin } from "./pages/signin";
import { Form, Inactive } from "./pages/form";
import { Contact } from './pages/contactForm'
import { DocProfile } from './pages/Profile'
import { Signup } from "./pages/signup";
import { Plogin } from "./pages/patient/plogin";
import { Telemedicine } from './pages/doctor/telemidicine/telemedicine'
import { InClinic } from "./pages/doctor/telemidicine/inClinic";
import { DoctorDetailsInput } from "./pages/doctor/docDetails";
import { DocHome } from "./pages/doctor/docHome";
//import { NewUser } from "./pages/doctor/newUser";
import { Appointments } from "./pages/doctor/docAppointments";
import { Calander } from "./pages/doctor/docCalander";
import { History } from "./pages/doctor/docHistory";
import { Earnings } from "./pages/doctor/docEarnings";
import { DocUserDetails } from "./pages/doctor/docUserDetails";
import { SearchUser } from "./pages/doctor/users/search";
import { Edit } from "./pages/doctor/users/edit";
import { Show } from "./pages/doctor/users/show";
import { Add } from "./pages/doctor/users/add";
import { Consultation } from "./pages/doctor/users/consultation";
import { PreviousConsultations } from "./pages/doctor/users/history";


import { PatientHome } from "./pages/patient/patientHome";
import { SingleAppointment }  from './pages/doctor/singleAppointment'
import { ComingSoon } from "./pages/comingSoon";
import { DocList } from "./pages/patient/docList";
import { ClinicList } from "./pages/patient/clinicList";
import { LabList } from "./pages/patient/labTests/labList";
import { Calendar } from "./pages/patient/calendar";
import { BookAppointment } from "./pages/patient/bookAppoint";
import { MyAppointments } from "./pages/patient/myAppoints";
import { MyTests } from "./pages/patient/myLabTests";
import { PatientSingleAppointment } from "./pages/patient/singleAppoint";
import { PatientVideoCall } from "./pages/patient/patientTele";
import { UserProfileInput } from "./pages/patient/userDetails";
import { UserProfile } from "./pages/patient/userProfile";
import { PrevConsultations } from "./pages/patient/history/consultation";
import { BookLabTest } from "./pages/patient/bookLabTest";
import { BuyMedicine } from "./pages/patient/buyMedicine";
import { HomeVisit } from "./pages/patient/homeVisit";

import { NurseSignin } from './pages/nurse/login_nurse'
import { NurseSignup } from './pages/nurse/signup_nurse'
import { SearchUserNurse } from './pages/nurse/nurse'

import { LabProviderLogin } from "./pages/labProvider/login_lp";
import { LabProviderSignup } from "./pages/labProvider/signup_lp";
import { LabDashboard } from "./pages/labProvider/dashboard_lp";
import { LabBookings } from "./pages/labProvider/bookings_lp";
import { LabAllTests } from "./pages/labProvider/tests/myTests";
import { LabBookingHistory } from "./pages/labProvider/myHistory";

import { Privacy } from "./pages/privacyPolicy";
import { Camp } from "./pages/camp";
import  Exam  from './pages/camp/addEdit'

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <div className="App">

            <Route path="/camp" exact component={Camp} />
            <Route path="/camp/:action/:campId" exact component={Exam} />
            <Route path="/privacyPolicies" exact component={Privacy} />

            <Route path="/comingSoon" exact component={ComingSoon} />
            <Route path="/" exact component={Home} />
            <Route path="/signin" exact component={Signin} /> 
            <Route path="/form" exact component={Form} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/docprofile" exact component={DocProfile} /> 
            <Route path="/signup" exact component={Signup} />
            <Route path="/docHome" exact component={DocHome} />
            <Route path="/telemedicine/:appId/:type"  component={Telemedicine} />
            <Route path="/prescribe/:appId/:type"  component={InClinic} />
            <Route path="/docDetails" exact component={DoctorDetailsInput} />
            <Route path="/singleAppointment" exact component={SingleAppointment} />
            {/* <Route path="/searchUser" exact component={NewUser} /> */}
            <Route path="/docAppointments" exact component={Appointments} />
            <Route path="/docCalander" exact component={Calander} />
            <Route path="/docHistory" exact component={History} />
            <Route path="/earnings" exact component={Earnings} />
            <Route path="/docUserHome/:number/:foundAt" exact component={DocUserDetails} />
            <Route path="/searchUser" exact component={SearchUser} />
            <Route path="/addUser/:number" exact component={Add} />
            <Route path="/editUser/:patientId/:foundAt" exact component={Edit} />
            <Route path="/showUser/:patientId/:foundAt" exact component={Show} />
            <Route path="/consultation/:patientId/:foundAt" exact component={Consultation} />
            <Route path="/previousConsultations/:patientId/:foundAt" exact component={PreviousConsultations} />


            <Route path="/docList/:type/:clinicId/:clinicName" component={DocList} />
            <Route path="/clinicList" exact component={ClinicList} />
            <Route path="/labList" exact component={LabList} /> 
            <Route path="/calendar/:type/:docId/:clinicId/:clinicName" component={Calendar} />
            <Route path="/patientHome" exact component={PatientHome} />
            <Route path="/login" exact component={Plogin} /> 
            <Route path="/book/:type/:docId/:dayInt/:slotName/:time/:clinicId/:clinicName" component={BookAppointment} /> 
            <Route path="/appointments" exact component={MyAppointments} />
            <Route path="/labTests" exact component={MyTests} />
            <Route path="/appointment/:appId" exact component={PatientSingleAppointment} />
            <Route path="/patient/teleconsult/:appId/:dname" exact component={PatientVideoCall} />
            <Route path="/userInput" exact component={UserProfileInput} />
            <Route path="/userProfile" exact component={UserProfile} />
            <Route path="/consultations" exact component={PrevConsultations} />
            <Route path="/bookTest/:labId" exact component={BookLabTest} />
            <Route path="/buyMedicine" exact component={BuyMedicine} />
            <Route path="/homeVisit" exact component={HomeVisit} />

            <Route path="/nurse_login" exact component={NurseSignin} />
            <Route path="/nurse_signup" exact component={NurseSignup} />
            <Route path="/nurse" exact component={SearchUserNurse} />

            <Route path="/lab_provider_login" exact component={LabProviderLogin} />
            <Route path="/lab_provider_signup" exact component={LabProviderSignup} />
            <Route path="/lab_provider_dashboard" exact component={LabDashboard} />
            <Route path="/lab_provider_bookings" exact component={LabBookings} />
            <Route path="/lab_provider_all_tests" exact component={LabAllTests} />
            <Route path="/lab_provider_history" exact component={LabBookingHistory} />
          
          </div>
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;



