import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebase";

import { DocNav } from "../docNav";
import { UserMenu } from "../../../components/userLeftMenu"

const ShowComp = () => {
    let [user, setUser] = useState({})
    var params = useParams()

    useEffect(() => {
      db.collection(`${params.foundAt}`)
        .doc(`${params.patientId}`).get()
        .then((res) => {
          let data = res.data();
          setUser(data);
        });
    }, []);

    return (
      <div className="px-10 pb-10">
        <div className="flex items-center mt-5 space-x-3">
          <Link
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img className="w-7 h-7" src="https://nuvocliniq-test.web.app/back.svg"></img>
          </Link>
          <div className=" text-base">Patient details</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-blue-100"></div>
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Name</div>
              <div
                className={`${
                  user.name
                    ? "bg-blue-100 text-base text-blue-600"
                    : "bg-red-100 text-red-600"
                } rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.name ? user.name : "Not available"}
              </div>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Number</div>
              <div className="rounded-md w-max text-base px-2 py-1 bg-blue-100 text-blue-600 mt-2 mb-5">
                {user.number ? user.number : "Not available"}
              </div>
            </div>

            <div className="w-full">
              <div className="text-gray-500 text-sm">Email Address</div>
              <div
                className={`${
                  user.email
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } rounded-md text-base px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.email ? user.email : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Address</div>
              <div className="pr-5">
                <div
                  className={`${
                    user.address
                      ? "bg-blue-100 text-blue-600"
                      : "bg-red-100 text-red-600"
                  } text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                >
                  {user.address ? user.address : "Not available"}
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Weight (in kilograms)</div>
              <div
                type="number"
                className={`${
                  user.weight
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.weight ? user.weight : "Not available"}
              </div>
            </div>
          </div>
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className="text-gray-500 text-sm">City</div>
              <div
                className={`${
                  user.city
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.city ? user.city : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Gender</div>
              <div
                className={`${
                  user.gender
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.gender ? user.gender : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Age (in years)</div>
              <div
                type="number"
                className={`${
                  user.age
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.age ? user.age : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">
                Height (in centimeters)
              </div>
              <div
                className={`${
                  user.height
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.height ? user.height : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Aadhar Number</div>
              <div
                className={`${
                  user.aadhar
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {user.aadhar ? user.aadhar : "Not available"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-0.5 -mt-3 border-t-2 border-blue-100"></div>
      </div>
    );
  };

  export const Show = () => {
    return (
      <div className=" min-w-full min-h-screen flex bg-white">
        <UserMenu activeComponent={'show'} />
        <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
          <DocNav/>
          <ShowComp />
        </div>
      </div>
    );
  }
