import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../../contexts";
import { PatientNav } from "./patientNav";
import { CityList } from "../../components/dropdownList";

import Lottie from "react-lottie";
var loader = require("../../components/nuvoLoader.json");

export const ClinicList = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [clinicsArray, setClinicsArray] = useState([]);
  let [loading, setLoading] = useState(true);
  let [cityDrop, setCityDrop] = useState({
    show: false,
    value: "Bhopal",
  });

  var params = useParams();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  useEffect(() => {
    db.collection("clinics")
      .where("city", "==", cityDrop.value)
      .get()
      .then((res) => {
        let tempArray = [];
        res.forEach((doc) => {
          tempArray.push(doc);
        });
        setLoading(false);
        setClinicsArray(tempArray);
      });
  }, [cityDrop.value]);

  const ClinicCard = (props) => {
    var item = props.item;
    return (
      <Link
        to={`/docList/clinics/${item.id}/${item.data().name}`}
        className="rounded-md border-2 sm:w-80 w-full border-gray-100 shadow-md mt-4 mt:  sm:m-5 pb-2 overscroll-x-none "
      >
        <div className=" w-full sm:w-80">
          <div className="sm:flex w-full">
            <div className="p-3 sm:py-2 sm:px-2  w-full sm:w-4/12 flex justify-center items-center">
              <img
                className=" w-16 h-16 rounded-full"
                src={item.data().img ? item.data().img : "logo.png"}
              ></img>
            </div>
            <div className="flex flex-col sm:mt-2 mt-0 justify-center items-center sm:items-start w-full sm:w-8/12  py-1 pr-2">
              <div className="font-semibold">{item.data().name}</div>
              <div className="mt-1" style={{fontSize:"15px"}}>{item.data().address}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const ClinicList = () => {
    if (clinicsArray.length === 0)
      return (
        <div className="w-full h-96 mt-20 flex justify-center items-center ">
          <div className="flex flex-col items-center">
            <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center rounded-md text-sm mt-5 text-gray-800">
              No clinic in this city
            </div>
          </div>
        </div>
      );
    else if (clinicsArray)
      return (
        <div className="flex flex-wrap justify-start sm:px-5 px-2 pb-2">
          {clinicsArray.map((item) => {
            return <ClinicCard item={item} />;
          })}
        </div>
      );
  };

  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen absolute z-10">
        <div className="flex items-center justify-center h-64 w-64 rounded-full">
          <Lottie options={defaultOptions} height={350} width={350} />
        </div>
      </div>
    );
  else
    return (
      <div >
        <PatientNav />
        <Link
          onClick={(e) => {
            e.preventDefault();
            window.history.go(-1);
          }}
          className="flex items-center text-gray-700 w-full justify-start sm:ml-3 ml-0 px-5 py-5 sm:p-5"
        >
          <img
            className="w-7 h-7 outline-none"
            src="https://nuvocliniq-test.web.app/back.svg"
          ></img>
          <div className="mx-3">List of clinics</div>
        </Link>
        <div >
          <div
            className={`${
              params.type === "teleconsult" ? "hidden" : ""
            } sm:mx-10 mx-2`}
          >
            <div className="text-gray-700 text-base">Select your city</div>
            <div class="relative inline-block text-left mt-2 w-56 ">
              <CityList cityDrop={cityDrop} setCityDrop={setCityDrop} />
            </div>
          </div>
          {/* <div
            className={`${
              params.type === "clinics" ? "hidden" : ""
            } sm:mx-10 mx-2`}
          >
            <div className=" text-black mt-3 text-lg">{`All clinics in ${cityDrop.value}`}</div>
          </div> */}
          <div>
            <ClinicList />
          </div>
        </div>
      </div>
    );
};
