import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../contexts";
import firebase from "firebase/app";
import Razorpay from "razorpay";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { db } from "../../firebase";
import { PatientNav } from "./patientNav";

import { useSelector, useDispatch } from "react-redux";

const sendToast = (message, type) => {
  const tostMsg = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  if (type === "error") toast.error(message, tostMsg);
  else if (type === "info") toast.info(message, tostMsg);
  else if (type === "success") toast.success(message, tostMsg);
};

export const BookLabTest = () => {

  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [activeComp, setActiveComp] = useState("inputForm");


  const DetailsInput = (props) => {
    let details = loggedIn.userDetails;
   
    const testsGlobal = useSelector((state) => state.testsGlobal);
    const dispatch = useDispatch();

    var nameRef = useRef();
    var addressRef = useRef()
    var pinRef = useRef()
    var genderRef = useRef();
    var ageRef = useRef();
    var emailRef = useRef();
    var numberRef = useRef();

    const handleNext = (e) => {
      e.preventDefault();
  
      let name = nameRef.current.value;
      let address = addressRef.current.value 
      let pinCode = pinRef.current.value
      let gender = genderRef.current.value;
      let age = ageRef.current.value;
      let email = emailRef.current.value;
      let number = numberRef.current.value;
  
      const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if (
        !name ||
        !gender ||
        !address ||
        !age ||
        !number ||
        !pinCode
      ) {
        sendToast("All fields marked with * are important", "error");
      } else if (age > 130 || age < 0 || age === 0) {
        sendToast("Age is not valid", "error");
      } else if (number.length !== 10) {
        sendToast("Enter 10 digits Mobile number", "info");
      } else if (email && !reEmail.test(String(email).toLowerCase())) {
        sendToast("Enter valid Email", "info");
      } else {
        let newTest = {
          address: address,
          age: age,
          email: email,
          gender: gender,
          pinCode: pinCode,
          name: name,
          number: "+91" + number, 
        };
        //console.log(newTest)
        dispatch({ type: "userDetails", payload: newTest });
        setActiveComp("testSearch");
      }
    };

    return (
      <div className="flex flex-col items-center mt-7">
        <div className="md:w-6/12 ">
          <div className="flex items-start ">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className=" text-gray-700"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq.in/back.svg"
              ></img>
            </Link>
            <div className="ml-4 text-base text-gray-600">Book lab test</div>
          </div>
          <div className="space-y-5 mt-5">
            <div className="mb-2 flex items-center  outline-none">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({type: 'selfPatient', payload: testsGlobal.selfPatient ? false : true})
                }}
                className={`${testsGlobal.selfPatient? 'border-blue-300': 'border-gray-400'} border-2 text-blue-500 outline-none rounded mr-2 h-5 w-5 p-1 flex items-center justify-center`}
              ><i className={`fas fa-check outline-none ${testsGlobal.selfPatient?'':'hidden'}`}></i></button>
              <div className="text-base">Are you yourself patient?</div>
            </div>
            <div name="name">
              <div className="text-base">
                Patient's Name <span className="text-red-600">*</span>
              </div>
              <div className=" ">
                <input
                  defaultValue={testsGlobal.selfPatient ? details.name : ""}
                  ref={nameRef}
                  type="text"
                  className="border-2 border-gray-200 bg-gray-50 mt-2  mr-5  px-3 py-2 rounded-md  outline-none"
                  placeholder="Enter your name"
                ></input>
              </div>
            </div>
            <div name="address" className="flex flex-col ">
              <div className="text-base">
                Sample Collection Address{" "}
                <span className="text-red-600">*</span>
              </div>
              <input defaultValue={testsGlobal.selfPatient ? details.address : ""}
                ref={addressRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2  px-3 py-2 rounded-md  outline-none"
                placeholder="Enter your address"
              ></input>
            </div>
            <div name="pin code" className="flex flex-col ">
              <div className="text-base">
                Pin code{" "}
                <span className="text-red-600">*</span>
              </div>
              <input defaultValue={testsGlobal.selfPatient ? details.pinCode : ""}
                ref={pinRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2 w-44  px-3 py-2 rounded-md  outline-none"
                placeholder="Enter your pin code"
              ></input>
            </div>
            
            <div className="my-5">
              <div className="text-base">
                Patient's Gender <span className="text-red-600">*</span>
              </div>
              <select defaultValue={testsGlobal.selfPatient ? details.gender : ""}
                ref={genderRef}
                type="dropdown"
                className="border-2 border-gray-200 bg-gray-50 px-3 py-2 mt-2 rounded-md  outline-none"
              >
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
            <div name="age">
              <div className="text-base">
                Patient's Age <span className="text-red-600">*</span>
              </div>
              <input defaultValue={testsGlobal.selfPatient ? details.age : ""}
                placeholder="Enter your age"
                ref={ageRef}
                type="number"
                className="border-2 border-gray-200 bg-gray-50 w-min  px-3 py-2 mt-2 rounded-md outline-none"
              ></input>
            </div>
            <div name="aadhar" className="my-5">
              <div className="text-base">
                Mobile Number <span className="text-red-600">*</span>
              </div>
              <input defaultValue={testsGlobal.selfPatient ? `${details.number}`.slice(3) : ""}
                placeholder="Enter 10 digit number"
                ref={numberRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2 px-3 py-2 rounded-md outline-none"
              ></input>
            </div>
            <div name="email">
              <div className="text-base">Email </div>
              <input defaultValue={testsGlobal.selfPatient ? details.email : ""}
                placeholder="Enter your email"
                ref={emailRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2  px-3 py-2 rounded outline-none"
              ></input>
            </div>
            <div className="pt-3 pb-7">
              <Link
                onClick={handleNext}
                className="bg-green-600 hover:bg-green-700 rounded text-white px-7 py-3"
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ActiveComponent = (props) => {
    if (activeComp === "inputForm") return <DetailsInput />;
    if (activeComp === "testSearch")
      return <PatientTestSuggest setActiveComp={setActiveComp} />;
    if (activeComp === "selectedTests")
      return <SelectedTests setActiveComp={setActiveComp} />;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PatientNav />
      <ActiveComponent />
    </>
  );
};

const TestCard = (props) => {
  let item = props.item;

  const testsGlobal = useSelector((state) => state.testsGlobal);
  const dispatch = useDispatch();

  return (
    <div className="flex justify-between items-center sm:w-80 border-2 border-gray-100 shadow pt-2 pb-3 px-5 rounded mt-3">
      <div style={{ fontSize: "15px" }} className="space-y-2">
        <div className={`${testsGlobal.idArray.includes(item.id) ? 'text-green-600' : ""}`}>{item.name}</div>
        <div className="flex">
          <div className="bg-blue-100 text-blue-600 rounded px-2 py-1">
            {item.sample}
          </div>
          <div className="bg-yellow-100 text-yellow-600 rounded px-2 py-1 ml-2">
            {item.price}
          </div>
        </div>
      </div>
      <div>
        <Link
          className={`${testsGlobal.idArray.includes(item.id) ? "hidden" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            console.log(item.id);
            dispatch({ type: "testSelected", payload: item, id: item.id });
          }}
        >
          <i className="fas fa-plus p-1 border-2 rounded-full text-gray-700 border-black"></i>
        </Link>
        <Link
          className={`${testsGlobal.idArray.includes(item.id) ? "" : "hidden"}`}
          onClick={(e) => {
            e.preventDefault();
            //console.log(item.id);
            dispatch({ type: "testUnselected", id: item.id });
          }}
        >
          <i className="fas fa-check-circle text-green-600 text-2xl"></i>
        </Link>
      </div>
    </div>
  );
};

const PatientTestSuggest = (props) => {
 
  const testsGlobal = useSelector((state) => state.testsGlobal);
  const dispatch = useDispatch();

  let [matchedTests, setMatchedTests] = useState([]);

  let searchRef = useRef();
  let params = useParams()

  useEffect(() => {
    if (testsGlobal.total.length === 0)
    db.collection('labs').doc(`${params.labId}`).collection('testList').orderBy('priority').get()
    .then(res => {
      //setTestArray(res.docs)
      let temp = res.docs.map(item => { return item.data()})
      console.log(temp)
      dispatch({ type: "totalTests", payload: temp });
    })
  }, [])

  const handleInputChange = (e) => {
    e.preventDefault();
    let temparray = testsGlobal.total.filter((item) => {
      if (
        item.name.toLowerCase().includes(searchRef.current.value.toLowerCase())
      )
        return item;
    });
    setMatchedTests(temparray);
    //console.log(temparray);
  };

  const TestsList = () => {
    if (matchedTests.length !== 0)
    return (
      <div>
        {matchedTests.map((item) => {
          return <TestCard item={item} />;
        })}
      </div>
    );
    if (matchedTests.length === 0)
    return (
      <div>
        {testsGlobal.total.map((item) => {
          return <TestCard item={item} />;
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center mt-7">
      <div className="md:w-6/12 pb-10">
        <div className="flex items-start ">
          <Link
            onClick={(e) => {
              e.preventDefault();
              props.setActiveComp("inputForm");
            }}
            className=" text-gray-700"
          >
            <img
              className="w-7 h-7 outline-none"
              src="https://nuvocliniq.in/back.svg"
            ></img>
          </Link>
          <div className="ml-4 text-base text-gray-600">Select tests</div>
        </div>
        <div className="mt-7">
          <input
            className="border-2 border-gray-200 outline-none rounded py-2 px-3"
            ref={searchRef}
            onChange={handleInputChange}
            placeholder="Search tests here"
          ></input>
        </div>
        <TestsList />
        <div className="mt-7 mb-10">
          <Link
            onClick={(e) => {
              e.preventDefault();
              props.setActiveComp("selectedTests");
            }}
            className="bg-green-600 hover:bg-green-700 fixed bottom-1  rounded text-white px-7 py-3"
          >
            Next
          </Link>
        </div>
      </div>
    </div>
  );
};

const SelectedTests = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  const testsGlobal = useSelector((state) => state.testsGlobal);
  let totalAmount = 0

  for (let i=0; i<testsGlobal.selected.length; i++) {
    totalAmount += testsGlobal.selected[i].price
  }

  const TestsList = () => {
    return (
      <div>
        {testsGlobal.selected.map((item) => {
          return <TestCard item={item} />;
        })}
      </div>
    );
  };

  const handlePayLater = (e) => {
    e.preventDefault()

    let newTest = testsGlobal.details
    newTest.createAt = firebase.firestore.FieldValue.serverTimestamp()
    newTest.amount = totalAmount
    newTest.patientId = loggedIn.currentUser.uid
    newTest.tests = testsGlobal.selected
    newTest.paymentType = "Sample collection"
    newTest.paymentStatus = false
    //console.log(newTest)
    db.collection('test').add(newTest)
    .then(res => {
      sendToast('Successfully submitted your request!', 'success')
      setTimeout(function(){ window.location.href='/patientHome' }, 2000);
    })
    .catch(err => console.log(err))
  }

  const handlePay = (e) => {
    e.preventDefault()

    const pay = async () => {
      const instance = await new Razorpay({
        key_id: process.env.REACT_APP_RAZOR_PAY_API_ID,
        key_secret: process.env.REACT_APP_RAZOR_PAY_API_SECRET_ID,
      });
      //console.log(instance)
      var orderOption = {
        amount: totalAmount * 100,
        currency: "INR",
        receipt: "order_rcptid_11",
      };
      const order = instance.orders.create(orderOption);
      //console.log(order)
      var options = {
        key: process.env.REACT_APP_RAZOR_PAY_API_ID,
        amount: `${totalAmount}` + "00",
        currency: "INR",
        name: loggedIn.userDetails.name,
        description: "Paying to Nuvocliniq",
        image: loggedIn.userDetails.name,
        order_id: order.id,
        handler: async function (response) {
          let newTest = testsGlobal.details
          newTest.createAt = firebase.firestore.FieldValue.serverTimestamp()
          newTest.amount = totalAmount
          newTest.patientId = loggedIn.currentUser.uid
          newTest.tests = testsGlobal.selected
          newTest.paymentType = "Sample collection"
          newTest.paymentStatus = true
          newTest.transId = response.razorpay_payment_id

          db.collection('test').add(newTest)
          .then(res => {
            sendToast('Successfully submitted your request!', 'success')
            setTimeout(function(){ window.location.href='/patientHome' }, 2000);
          })
          .catch(err => console.log(err))
        },
        prefill: {
          name:loggedIn.userDetails.name,
          email: loggedIn.userDetails.email,
          contact: loggedIn.userDetails.number,
        },
        notes: {
          address: "Nuvocliniq",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    };
    pay()
  }

  return (
    <div className="flex flex-col items-center mt-7">
      <div className="md:w-6/12 ">
        <div className="flex items-start ">
          <Link
            onClick={(e) => {
              e.preventDefault();
              props.setActiveComp("testSearch");
            }}
            className=" text-gray-700"
          >
            <img
              className="w-7 h-7 outline-none"
              src="https://nuvocliniq.in/back.svg"
            ></img>
          </Link>
          <div className="ml-4 text-base text-gray-600">Selected tests</div>
        </div>
        <TestsList />
        <div className='flex justify-between w-80 mt-5'>
          <div>Total amount</div>
          <div className='text-green-600 flex items-center'>{`${totalAmount}₹`}</div>
        </div>
        <div className="mt-5 mb-7 flex">
          <Link
            onClick={handlePayLater}
            className="bg-blue-600 hover:bg-blue-700 rounded text-white px-7 py-3"
          >
            Pay on sample collection
          </Link>
          <Link
            onClick={handlePay}
            className="bg-green-600 hover:bg-green-700 ml-2 rounded text-white px-7 py-3"
          >
            Pay online
          </Link>
        </div>
      </div>
    </div>
  );
};
