import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import firebase from "firebase/app";
import { db } from "../../firebase";
import { useAuth } from "../../contexts";
import { PatientNav } from "./patientNav";

import Razorpay from "razorpay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const BookAppointment = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());

  if (!loggedIn.currentUser) window.location.href = "/login";

  let [docInfo, setDocInfo] = useState({});
  let [patientInfo, setPatientInfo] = useState({});
  let [payable, setPayable] = useState();
  var params = useParams();

  var couponRef = useRef();
  //console.log(loggedIn.currentUser.uid)
  useEffect(() => {
    db.collection("doctors")
      .doc(params.docId)
      .get()
      .then((res) => {
        setDocInfo(res.data());
        setPayable(res.data().fee);
        db.collection("patients")
          .doc(loggedIn.currentUser.uid)
          .get()
          .then((result) => {
            setPatientInfo(result.data());
          });
      });
  }, []);

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };
  const calcPayable = () => {
    let coupon = couponRef.current.value;
    if (coupon.length === 6) {
      db.collection("coupon")
        .doc(coupon)
        .get()
        .then((cres) => {
          if (!cres.data()) {
            sendToast("invalid coupon code", "error");
            setPayable(docInfo.fee);
          } else {
            let tempP = `${Math.ceil(
              parseInt(docInfo.fee) -
                parseInt(docInfo.fee) * (parseInt(cres.data().value) / 100)
            )}`;
            setPayable(tempP);
          }
        });
    }
  };

  //console.log(patientInfo, docInfo)
  let dt = `${params.dayInt.toString().slice(6)}`;
  let month = `${params.dayInt.toString().slice(4, 6)}`;
  let year = `${params.dayInt.toString().slice(0, 4)}`;

  var appointInt = parseInt(`${year}${month}${dt}${params.slotName}`);
  var dateString = `${dt}-${month}-${year}`;

  //console.log(newDate, dt, month, year)
  //console.log(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  const handlePay = (e) => {
    db.collection("doctors")
      .doc(`${params.docId}`)
      .collection("dates")
      .doc(`${params.dayInt}`)
      .collection("slots")
      .doc(`${params.slotName}`)
      .get()
      .then((res) => {
        if (res.data() && res.data().status === "booked") {
          sendToast("Slot is already booked.", "error");
        } else {
          const pay = async () => {
            const instance = await new Razorpay({
              key_id: process.env.REACT_APP_RAZOR_PAY_API_ID,
              key_secret: process.env.REACT_APP_RAZOR_PAY_API_SECRET_ID,
            });
            //console.log(instance)
            var orderOption = {
              amount: parseInt(payable) * 100,
              currency: "INR",
              receipt: "order_rcptid_11",
            };
            const order = instance.orders.create(orderOption);
            //console.log(order)
            var options = {
              key: process.env.REACT_APP_RAZOR_PAY_API_ID,
              amount: payable + "00",
              currency: "INR",
              name: patientInfo.name,
              description: "Paying to Nuvocliniq",
              image: patientInfo.img,
              order_id: order.id,
              handler: async function (response) {
                var transRef = db.collection("transaction").doc();
                var appointRef = db.collection("appointment").doc();
                //console.log(transRef.id, appointRef.id)
                var newAppoint = {
                  appointInt: appointInt,
                  couponCode: couponRef.current.value,
                  createAt: new Date(),
                  date: dateString,
                  doctorId: params.docId,
                  email: docInfo.email,
                  fee: payable,
                  name: patientInfo.name,
                  number: patientInfo.number,
                  patientId: loggedIn.currentUser.uid,
                  paymenType: "Online",
                  payment: "Prepaid",
                  paymentId: transRef.id,
                  paymentStatus: "true",
                  slot: params.time,
                  slotId: params.slotName,
                  status: "Scheduled",
                  transId: response.razorpay_payment_id,
                  type:
                    params.type === "teleconsult"
                      ? "Telemedicine"
                      : "In Clinic",
                  viewtype: 0,
                };

                if (params.type === "clinics") {
                  newAppoint.clinicId = params.clinicId;
                  newAppoint.clinicName = params.clinicName;
                }

                var newTrans = {
                  amount: payable,
                  appointId: appointRef.id,
                  createAt: new Date(),
                  number: patientInfo.number,
                  status: "true",
                  type: "Online",
                  transId: response.razorpay_payment_id,
                  userId: loggedIn.currentUser.uid,
                };
                await db
                  .collection("appointment")
                  .doc(appointRef.id)
                  .set(newAppoint)
                  .then((appointRes) => {
                    db.collection("doctors")
                      .doc(`${params.docId}`)
                      .collection("dates")
                      .doc(`${params.dayInt}`)
                      .collection("slots")
                      .doc(`${params.slotName}`)
                      .update({
                        status: "booked",
                        appointId: appointRef.id,
                      });

                    db.collection("doctors")
                      .doc(`${params.docId}`)
                      .collection("dates")
                      .doc(`${params.dayInt}`)
                      .update({
                        isBooked: firebase.firestore.FieldValue.increment(1),
                        isTrue: firebase.firestore.FieldValue.increment(-1),
                      });

                    db.collection("transaction")
                      .doc(transRef.id)
                      .set(newTrans)
                      .then((transRes) => {
                        db.collection("notifications")
                          .doc()
                          .set({
                            appointId: appointRef.id,
                            content: "Appointment successfully booked",
                            doctorId: params.docId,
                            title: "Appointment successfully booked",
                            type: "appointment",
                          })
                          .then((notRes) => {
                            sendToast(
                              "Successfully booked your appointment",
                              "success"
                            );
                            window.location.href = "/appointments";
                          });
                      });
                  });
              },
              prefill: {
                name: patientInfo.name,
                email: patientInfo.email,
                contact: patientInfo.number,
              },
              notes: {
                address: "Nuvocliniq",
              },
              theme: {
                color: "#3399cc",
              },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
            });
            rzp1.open();
          };
          pay();
        }
      });
  };

  const handleBook = async (e) => {
    e.preventDefault();
    var appointRef = db.collection("appointment").doc();
    //console.log(transRef.id, appointRef.id)
    var newAppoint = {
      appointInt: appointInt,
      clinicId: params.clinicId,
      clinicName: params.clinicName,
      createAt: new Date(),
      date: dateString,
      doctorId: params.docId,
      email: docInfo.email,
      fee: docInfo.fee,
      name: patientInfo.name,
      number: patientInfo.number,
      patientId: loggedIn.currentUser.uid,
      paymenType: "Cash",
      payment: "In Clinic",
      paymentStatus: "false",
      slot: params.time,
      slotId: params.slotName,
      status: "Scheduled",
      type: "In Clinic",
      viewtype: 0,
    };
    await db
      .collection("doctors")
      .doc(`${params.docId}`)
      .collection("dates")
      .doc(`${params.dayInt}`)
      .collection("slots")
      .doc(`${params.slotName}`)
      .get()
      .then((res) => {
        if (res.data() && res.data().status === "booked") {
          sendToast("Slot is already booked.", "error");
        } else {
          console.log("in else");
          db.collection("appointment")
            .doc(appointRef.id)
            .set(newAppoint)
            .then((appointRes) => {
              db.collection("doctors")
                .doc(`${params.docId}`)
                .collection("dates")
                .doc(`${params.dayInt}`)
                .collection("slots")
                .doc(`${params.slotName}`)
                .update({
                  status: "booked",
                  appointId: appointRef.id,
                });
              db.collection("doctors")
                .doc(`${params.docId}`)
                .collection("dates")
                .doc(`${params.dayInt}`)
                .update({
                  isBooked: firebase.firestore.FieldValue.increment(1),
                  isTrue: firebase.firestore.FieldValue.increment(-1),
                });

              db.collection("notifications")
                .doc()
                .set({
                  appointId: appointRef.id,
                  content: "Appointment successfully booked",
                  doctorId: params.docId,
                  title: "Appointment successfully booked",
                  type: "appointment",
                })
                .then((notRes) => {
                  sendToast("Successfully booked your appointment", "success");
                  window.location.href = "/appointments";
                });
            });
        }
      });
  };
  return (
    <div>
      <PatientNav />
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className=" text-lg text-gray-600">
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.history.go(-1);
            }}
            className="flex items-center text-gray-700 w-max justify-start sm:ml-3 px-4 py-5 sm:p-5"
          >
            <img
              className="w-7 h-7 outline-none"
              src="https://nuvocliniq-test.web.app/back.svg"
            ></img>
            <div className="mx-4">Appointment details</div>
          </Link>
          <div className="sm:px-10 px-4 pb-5 pt-1 w-full sm:flex">
            <div className="sm:w-6/12">
              <div className="w-full">
                <div className="text-gray-500 text-sm">Patient's Name</div>
                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-blue-100 text-base text-blue-600">
                  {patientInfo.name ? patientInfo.name : ""}
                </div>
              </div>

              <div className="w-full">
                <div className="text-gray-500 text-sm">Phone Number</div>

                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-blue-100 text-base text-blue-600">
                  {patientInfo.number ? patientInfo.number : "No number"}
                </div>
              </div>

              <div className="w-full">
                <div className="text-gray-500 text-sm">Date</div>
                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-yellow-100 text-base text-yellow-600">{`${params.dayInt
                  .toString()
                  .slice(6)}-${params.dayInt
                  .toString()
                  .slice(4, 6)}-${params.dayInt.toString().slice(0, 4)}`}</div>
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Time slot</div>
                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-green-100 text-base text-green-600">
                  {" "}
                  {params.time}
                </div>
              </div>
            </div>
            <div className="sm:w-6/12">
              <div className="w-full">
                <div className="text-gray-500 text-sm">Appointment type</div>
                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-blue-100 text-base text-blue-600">
                  {params.type === "teleconsult" ? "Teleconsult" : "In Clinic"}
                </div>
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Amount</div>
                <div className="rounded px-2 py-1 mt-4 mb-5 w-max bg-blue-100 text-base text-blue-600">
                  {`${docInfo.fee} Rs.`}
                </div>
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Coupon code</div>
                <div className="sm:flex items-center sm:space-x-3 mt-4 mb-5">
                  <input
                    style={{ width: "250px" }}
                    ref={couponRef}
                    placeholder="Enter coupon code"
                    className="rounded outline-none px-2 py-1 border-2"
                  ></input>

                  <button
                    onClick={calcPayable}
                    className="bg-green-600 border-2 mt-2 sm:mt-0 border-green-600 px-2 py-1 rounded text-base text-white"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="w-full">
                <div className="text-gray-500 text-sm">Payable Amount</div>
                <div className="rounded-md px-2 py-1 mt-4 mb-5 w-max bg-blue-100 text-base text-blue-600">
                  {payable} Rs.
                </div>
                <button
                  className="bg-green-600 hover:bg-green-700 text-base text-white rounded px-3 py-2"
                  onClick={handlePay}
                  id="rzp-button1"
                >
                  Pay Online
                </button>
                <button
                  className={`${
                    params.type === "teleconsult" ? "hidden" : ""
                  } mx-2 sm:mx-4 bg-blue-600 text-base hover:bg-blue-700 text-white rounded px-3 py-2`}
                  onClick={handleBook}
                >
                  Pay At Clinic
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
