import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { db, storage } from "../../firebase";
import { VendorsDropDown } from "./components/vendorsDrop";
import { CampDropDown } from "./components/campDrop";
import DeleteIcon from "../../assets/delete.svg";
import EditIcon from "../../assets/edit.svg";
import PdfIcon from "../../assets/pdf.png";

export const Camp = () => {
  let [vendorsArray, setVendorsArray] = useState([]);
  let [vendorsDrop, setVendorsDrop] = useState({
    show: false,
    value: { name: "Select Vendor" }, //vendorsArray[0],
  });

  let [campDrop, setCampDrop] = useState({
    show: false,
    value: { name: "Select Camp" }, //vendorsArray[0],
  });
  let [campArray, setCampArray] = useState([]);

  let [examsList, setExamsList] = useState([]);

  let [reportPdfLink, setReportPdfLink] = useState(null);

  useEffect(() => {
    db.collection("vendors")
      .get()
      .then((res) => {
        let tempArray = [];
        res.forEach((doc) => {
          tempArray.push(doc.data());
        });
        //console.log(tempArray)
        setVendorsArray(tempArray);
      });
  }, []);

  useEffect(() => {
    //console.log(vendorsDrop)
    if (vendorsDrop.value.id)
      db.collection("vendors")
        .doc(`${vendorsDrop.value.id}`)
        .collection("camps")
        .get()
        .then((res) => {
          let tempArray = [];
          res.forEach((doc) => {
            tempArray.push(doc.data());
          });
          //console.log(vendorsDrop)
          setCampArray(tempArray);
        });
  }, [vendorsDrop]);

  useEffect(() => {
    //console.log(campDrop.value.id, campDrop.value.id.length);
    if (campDrop.value.id)
      db.collection("camps")
        .doc(`${campDrop.value.id}`)
        .collection("exams")
        .get()
        .then((res) => {
          let tempArray = [];
          res.forEach((doc) => {
            tempArray.push(doc.data());
          });
          //console.log(tempArray);
          setExamsList(tempArray);
        });
  }, [campDrop]);

  const handleViewPdf = (examId) => {
    var storageRef = storage.ref();
    // db.collection(`${params.type}`)
    //   .doc(props.appId)
    //   .get()
    //   .then((res) => {
    //     if (!res.data() || !res.data().presCreateAt)
    //       sendToast("Prescription not generated yet", "info");
    //     else
    storageRef
      .child(`campReports/${examId}.pdf`)
      .getDownloadURL()
      .then((result) => {
        setReportPdfLink(result);
        document.getElementById("openReportPdf").click();
        //});
      });
  };

  return (
    <div className="space-y-5 m-6">
      <div className="flex justify-end space-x-5">
        <div>
          <VendorsDropDown
            vendorsDrop={vendorsDrop}
            setVendorsDrop={setVendorsDrop}
            vendorsArray={vendorsArray}
          />
        </div>
        <div>
          <CampDropDown
            campDrop={campDrop}
            setCampDrop={setCampDrop}
            campArray={campArray}
          />
        </div>

        {campDrop.value.id ? (
          <Link
            //onClick={()=>}
            to={`/camp/${"add"}/${campDrop.value.id}`}
            className={` rounded-md space-x-2 text-sm bg-blue-600 hover:bg-blue-700 text-white flex justify-center items-center px-3 mr-5 lg:mx-10 py-2 w-max`}
          >
            <i class="far fa-calendar-plus"></i>
            <div>Add</div>
          </Link>
        ) : null}
      </div>

      <div>
        
         {
             campDrop.value.id?(
                <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        Name
                      </div>
                    </Th>
                    <Th>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        Gender/Age
                      </div>
                    </Th>
                    <Th>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        Report
                      </div>
                    </Th>
                    <Th>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        Actions
                      </div>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                {examsList.map((item, index) => (
                  <Tr style={{ margin: "10px 0px 10px 0px" }} key={index}>
                    <Td>{item.name}</Td>
                    <Td>
                      {item.gender} {item.age}
                    </Td>
                    <Td>
                      {item.report ? (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleViewPdf(item.id);
                          }}
                          //className="rounded-md py-2 px-3 text-white bg-blue-500 hover:bg-blue-700 font-semibold text-lg"
                        >
                          <img
                            src={PdfIcon}
                            style={{ height: "25px", width: "50px" }}
                          ></img>
                        </Link>
                      ) : (
                        <div className="pl-4">NA</div>
                      )}
                    </Td>
                    <Td style={{ display: "flex" }}>
                      <Link
                        //onClick={()=>}
                        to={`/camp/${item.id}/${campDrop.value.id}`}
                        //className={` rounded-md space-x-2 text-sm bg-blue-600 hover:bg-blue-700 text-white flex justify-center items-center px-3 mr-5 lg:mx-10 py-2 w-max`}
                      >
                        <img
                          style={{ height: "25px", width: "25px" }}
                          src={EditIcon}
                        ></img>
                      </Link>{" "}
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                          margin: "0px 0px 20px 10px",
                        }}
                        src={DeleteIcon}
                      ></img>
                    </Td>
                  </Tr>
                   
                ))}
             </Tbody>
                   </Table>
             ):(
                <div className="w-full h-96 mt-20 flex justify-center items-center">
                <div className="flex flex-col items-center">
                    <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
                    <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
                        Please select a vendor and a camp to view data.
                    </div>
                </div>
            </div>
             )
         }
          
       
        <a href={reportPdfLink} target="_blank" id="openReportPdf"></a>
      </div>
    </div>
  );
};
