import React, { useState } from "react";
import { Link } from "react-router-dom";
//import { db } from "../../firebase";
import { useAuth } from "../../contexts";
import { PatientNav } from "./patientNav";
import { db, auth } from "../../firebase";

export const PatientHome = () => {
  let [loggedIn, setLoggedIn] = useState(useAuth());

  if (!loggedIn.currentUser) window.location.href = "/login";

  else if (
    loggedIn.currentUser &&
    !loggedIn.userDetails
  ) {
    auth
      .signOut()
      .then((res) => (window.location.href = "/"))
      .catch((err) => console.log("false"));
  }
  else if (
    loggedIn.currentUser &&
    loggedIn.currentUser.displayName === "doctor"
  )
    window.location.href = "/docAppointments";

  db.collection("patients")
    .doc(`${loggedIn.currentUser.uid}`)
    .get()
    .then((res) => {
      if (!res.data().name) window.location.href = "/userInput";
    });
  return (
    <div className="overflow-x-hidden overscroll-x-none">
      <div>
        <PatientNav />
      </div>
      <div className="w-screen h-screen">
        <div
          style={{
            backgroundImage: "url(/bg1.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
          className="pb-14 overflow-x-hidden"
        >
          <div className=" lg:px-36 md:px-12 sm:px-10 sm:py-10 py-5 flex justify-center ">
            <div name='main container' className="flex flex-col md:flex-row  items-center justify-center w-max">
              <div className=" md:w-4/12 h-96  text-white bg-gradient-to-br to-green-400 from-green-600 sm:mr-10 rounded-md md:p-8 p-3">
                <div className='w-full'>
                  <div className="font-semibold text-xl md:text-3xl  mb-3">
                    Services
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Primary
                    healthcare services
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Digital
                    diagnostics
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Expert
                    consultation-
                  </div>
                  <div className=" pl-9"> ENT, Cardiology, Chest etc.</div>
                  <div className="flex items-center">
                    <i className="fas fa-check-circle mr-3"></i>
                    <div>
                      <div> Teleconsultation, Assisted  </div>
                      <div>teleconsult</div>
                    </div>
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Vaccination
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Electronic
                    health data
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> AI based health
                    score
                  </div>
                </div>
              </div>
              <div className="md:w-4/12 h-96 text-white bg-gradient-to-br to-blue-400 from-blue-600 rounded-md md:p-8 p-3 mt-3 sm:mt-0">
                <div className='w-full'>
                  <div className="font-semibold text-xl md:text-3xl text-white mb-3 mt-5 sm:mt-0">
                    Pathology Services
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Blood Glucose
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Complete Urine
                    analysis
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Haemoglobin
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Total
                    Cholesterol
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Bilirubin
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> Fever panel
                  </div>
                  <div>
                    <i className="fas fa-check-circle mr-3"></i> ECG
                  </div>
                  <div className="flex items-center">
                    <i className="fas fa-check-circle mr-3"></i>
                    <div>
                      <div> Kidney and Lever Function </div>
                      <div>test</div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="order-first md:order-last flex justify-center  md:w-4/12 w-full sm:pl-8">
                <div className="flex flex-col w-max flex-wrap  justify-around">
                  <Link
                    to="/clinicList"
                    className="rounded-full hover:bg-blue-700 flex items-center py-2 px-5 bg-blue-600 text-white text-lg mx-2 sm:mx-5 my-5"
                  >
                    <i class="far fa-calendar-check"></i>
                    <div className="ml-2">In Clinic</div>
                  </Link>
                  <Link
                    to="/docList/teleconsult/list/all"
                    className="rounded-full hover:bg-green-700 flex items-center py-2 px-5 bg-green-600 text-white text-lg m-5"
                  >
                    <i class="fas fa-laptop-medical"></i>
                    <div className="ml-2">Teleconsult</div>
                  </Link>
                  <Link
                    to="/homeVisit"
                    className="rounded-full hover:bg-blue-700 flex items-center py-2 px-5 bg-blue-600 text-white text-lg m-5"
                  >
                    <i class="fas fa-home"></i>
                    <div className="ml-2">Book Home Visit</div>
                  </Link>
                  <Link
                    to="/buyMedicine"
                    className="rounded-full hover:bg-green-700 hover:text-white flex items-center py-2 px-5 border-2 border-green-600 text-green-600 text-lg m-5"
                  >
                    <i class="fas fa-cart-plus"></i>
                    <div className="ml-2">Buy Medicine</div>
                  </Link>
                  <Link
                    to="/labList"
                    className="rounded-full hover:bg-blue-600 hover:text-white flex items-center border-2 py-2 px-5 border-blue-600 text-blue-600 text-lg m-5"
                  >
                    <i class="fas fa-vial"></i>
                    <div className="ml-2">Book Lab Test</div>
                  </Link>
                  <Link
                    to="/form"
                    className="rounded-full hover:bg-green-700 hover:text-white flex items-center py-2 px-5 border-2 border-green-600 text-green-600 text-lg m-5"
                  >
                    <i class="fas fa-virus"></i>
                    <div className="ml-2">Covid-19 RT-PCR Form</div>
                  </Link>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
