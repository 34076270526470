import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import { Navbar } from "../../components/navbar";
import { db } from "../../firebase";
import { useAuth } from "../../contexts";
import { Footer } from "../../components/footer"; 

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Lottie from "react-lottie";
var loader = require("../../components/nuvoLoader.json");


export const Plogin = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [loading, setLoading] = useState(false);
  let [activeComp, setActiveComp] = useState("takeNumber");
  let [confirmRes, setConfirmRes] = useState();

  var numberRef = useRef();
  var otpRef = useRef();
  var checkboxRef = useRef()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const onSignInSubmit = (num) => {
    setCaptcha();

    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(`+91${num}`, appVerifier)
      .then((response) => {
        sendToast("OTP sent to your number", "info");
        setConfirmRes(response);
        setActiveComp("takeOtp");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const verifyOtp = (e, num) => {
    e.preventDefault();
    setLoading(true);
    window.confirmRes = confirmRes;
    var code = otpRef.current.value;
    confirmRes
      .confirm(code)
      .then(async (result) => {
        var user = result.user;
        console.log(result.user.uid, result.user);
        db.collection("patients")
          .doc(user.uid)
          .get()
          .then((res) => {
            if (!res.data()) {
              user.updateProfile({
                displayName: "patient",
              });
              db.collection("patients")
                .doc(user.uid)
                .set({
                  number: user.phoneNumber,
                })
                .then((done) => {
                  sendToast("Successfully created your account!", "success");

                  window.location.href = "/userInput";
                })
                .catch((err) => {
                  setLoading(false);
                  sendToast("Something went wrong", "error");
                });
            } else {
              sendToast("Successfully logged in!", "success");
              
              window.location.href = "/patientHome";
            }
          });
      })
      .catch((err) => {
        setLoading(false);
        sendToast("Something went wrong", "error");
      });
  };

  const setCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("solved..");
          onSignInSubmit();
        },
      }
    );
  };
  const ActiveComponent = () => {
    if (activeComp === "takeNumber")
      return (
        <div className="p-5">
          <div className="text-2xl text-gray-600 mb-5 font-semibold">Patient Login</div>
          <div className=" flex flex-col justify-center bg-white  rounded-md p-2 sm:py-5 px-3 sm:px-5 ">
            <div className=" text-gray-700 font-semibold">
              Enter your phone number to get started
            </div>
            <div className="mb-4 text-gm text-gray-600">
              You will receive a verification code.
            </div>
            <div className="flex items-center">
              <div className="border-2 border-gray-200 bg-gray-50 p-3 rounded-md mr-2">+91</div>
              <input
                ref={numberRef}
                placeholder="Enter phone number"
                className="border-2 border-gray-200 bg-gray-50 w-full outline-none rounded-md p-3 b"
              ></input>
            </div>
            <div id="recaptcha-container"></div>
            <div className='flex items-start mt-3'>
                <div >
                  <input className="w-6 h-6" ref={checkboxRef} type='checkbox' className='outline-none' value='accepted'></input>
                </div>
                <div className='ml-1' style={{fontSize:"15px"}}>
                  I have read and accept <Link to='/privacyPolicies' className='text-blue-600 font-semibold'>privacy policy</Link> of Nuvocliniq.
                </div>
            </div>
            <Link
              onClick={(e) => {
                e.preventDefault();
                var num = numberRef.current.value;
                if (num.length !== 10) sendToast("Please enter valid number", "error")
                else if (!checkboxRef.current.checked) sendToast('Please accept our privacy policies', 'error')
                 else {
                  setLoading(true);
                  onSignInSubmit(num);
                } ;

              }}
              className={`${loading?'hidden':''} rounded mt-4 w-max bg-blue-600 px-5 py-2 text-white `}
            >
              Send OTP
            </Link>
            <div className={`${loading?'':'hidden'}`}>
              <Lottie options={defaultOptions} height={100} width={100} />
            </div>
          </div>
        </div>
      );
    else if (activeComp === "takeOtp")
      return (
        <div className="p-5">
          <div className="text-2xl text-black mb-5 font-semibold">Patient Login</div>
          <div className=" flex flex-col justify-center bg-white sm:rounded-md sm:w-96 p-2 sm:py-5 sm:px-5 ">
            <div className="mb-2 text-gray-700 font-semibold">Verification code</div>
            <div className="flex items-center">
              <input
                ref={otpRef}
                placeholder="Enter verification code"
                className="border-2 bg-gray-50 border-gray-200 w-full outline-none rounded-md p-3 "
              ></input>
            </div>
            <Link
              onClick={verifyOtp}
              className={`${loading?'hidden':''} rounded mt-4 w-max bg-blue-500 px-5 py-2 text-white`}
            >
              Verify
            </Link>
            <div className={`${loading?'':'hidden'}`}>
              <Lottie options={defaultOptions} height={100} width={100} />
            </div>
          </div>
        </div>
      );
  };

  if (loggedIn.currentUser && loggedIn.currentUser.displayName !== "doctor") {
    window.location.href = "/patientHome";
    return(<div></div>)
  }
    
  else if (
    loggedIn.currentUser &&
    loggedIn.currentUser.displayName === "doctor"
  ) {
    window.location.href = "/docAppointments";
    return(<div></div>)
  }
  else 
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className=" min-h-screen ">
        <Navbar />
        <div
          style={{
            backgroundImage: "url(/bg1.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
          className="md:flex md:justify-between sm:pt-36 pt-44 pb-24"
        >
          <div className="md:w-7/12 h-5/6 md:flex flex flex-col justify-center items-center">
            <div className=" rounded-tl-3xl rounded-br-3xl rounded-md bg-gradient-to-br to-blue-200 from-blue-300 sm:mr-5 md:p-8 p-3">
              <div className="font-semibold text-xl md:text-3xl  mb-3">
                Services
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> Primary healthcare
                services
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> Digital diagnostics
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> Expert
                consultation-
              </div>
              <div className=" pl-9"> ENT, Cardiology, Chest etc.</div>
              <div className="flex items-center">
                <i className="fas fa-check-circle mr-3"></i>
                <div> Teleconsultation, Assisted teleconsult </div>
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> Vaccination
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> Electronic health
                data
              </div>
              <div>
                <i className="fas fa-check-circle mr-3"></i> AI based health
                score
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center md:w-5/12 h-5/6">
            <ActiveComponent />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
