import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../../contexts";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PatientNav } from "./patientNav";
import { GenderList } from "../../components/dropdownList";

export const UserProfileInput = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [initialData, setInitialData] = useState({});
  let [genderDrop, setGenderDrop] = useState({
    show: false,
    value: initialData.gender,
  });

  var nameRef = useRef();
  var emailRef = useRef()
  var addressRef = useRef();
  var ageRef = useRef();
  var heightRef = useRef();
  var weightRef = useRef();
  var aadharRef = useRef()

  useEffect(() => {
    db.collection("patients")
      .doc(loggedIn.currentUser.uid)
      .get()
      .then((res) => {
        setInitialData(res.data());
        //console.log(res.data())
        setGenderDrop({
          show: false,
          value: res.data().gender,
        })
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let address = addressRef.current.value;
    let weight = weightRef.current.value;
    let height = heightRef.current.value;
    let email = emailRef.current.value;
    let aadhar = aadharRef.current.value;
    let age = ageRef.current.value;
    let name = nameRef.current.value;

    if (!name)
      sendToast("Name should not be empty", "error");
    else if (!reEmail.test(String(email).toLowerCase()))
      sendToast("Enter valid Email", "error");
    else {
        db.collection("patients")
          .doc(loggedIn.currentUser.uid)
          .update({
            name: name,
            height: height,
            weight: weight,
            address: address,
            age: age,
            gender: genderDrop.value?genderDrop.value:'',
            email: email,
            aadhar: aadhar
          })
          .then((res) => {
            sendToast("successfully updated", "success");
            window.location.href='/patientHome'
          });
      }
    
  };
  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PatientNav/>
      <div className="sm:ml-10 mx-5 sm:w-1/2 md:w-4/12  overflow-x-hidden overscroll-x-none">
        <div className="mt-1 sm:px-0">
          <Link
              onClick={(e)=> {
                e.preventDefault()
                window.history.go(-1)
              }}
              className="flex items-center w-max text-gray-700  justify-start py-5"
            >
              <img className={`${
              !initialData.name
                ? "hidden"
                : ""
            } w-7 h-7 outline-none`} src="https://nuvocliniq-test.web.app/back.svg"></img>
              <div className="ml-4">User's details</div>
            </Link>
        </div>
        <div style={{ fontSize: "15px" }} className="flex flex-col w-full mb-7 space-y-4">
          
            <div > 
              <p className="flex  items-start">Name <span className="text-red-700"> *</span></p>
              <input
                ref={nameRef}
                defaultValue={initialData.name}
                className=" mt-2 p-3  w-full  outline-none  font-semibold bg-gray-50 border-2 border-gray-200 rounded-md"
              ></input>
            </div>
            <div>
              <p className="flex flex-col items-start">
                Email Address
              </p>
              <input
                ref={emailRef}
                defaultValue={initialData.email}
                className=" mt-2 p-3 font-semibold   w-full outline-none   bg-gray-50 border-2 border-gray-200 rounded-md"
              ></input>
            </div>
            <div>
              <p className="flex flex-col  items-start">
                Gender
              </p>
              <div class="relative inline-block text-left my-2 ">
                <GenderList
                  genderDrop={genderDrop}
                  setGenderDrop={setGenderDrop}
                />
              </div>
            </div>
            <div>
              <p className="flex flex-col  items-start">Age(In years)</p>
              <input
                ref={ageRef}
                defaultValue={initialData.age}
                className=" mt-2 p-3  w-full font-semibold outline-none  bg-gray-50 border-2 border-gray-200 rounded-md"
              >
              </input>
            </div>
            <div>
              <p className="flex flex-col   items-start">
                Height (In centimeter)
              </p>
              <input
                ref={heightRef}
                defaultValue={initialData.height}
                className=" mt-2 p-3 w-full  font-semibold outline-none  bg-gray-50 border-2 border-gray-200 rounded-md"
              >
              </input>
            </div>
            <div>
              <p className="flex flex-col text-base items-start">
                Weight (In kilograms)
              </p>
              <input
                ref={weightRef}
                defaultValue={initialData.weight}
                className=" mt-2 p-3 w-full  font-semibold outline-none  bg-gray-50 border-2 border-gray-200 rounded-md"
              ></input>
            </div>
            <div>
              <p className="flex flex-col  items-start">
               Address
              </p>
              <input
                ref={addressRef}
                defaultValue={initialData.address}
                className=" mt-2 p-3 w-full  font-semibold outline-none  bg-gray-50 border-2 border-gray-200 rounded-md"
              ></input>
            </div>
            <div>
              <p className="flex flex-col  items-start">
                Aadhar Number
              </p>
              <input
                ref={aadharRef}
                defaultValue={initialData.aadhar}
                className=" mt-2 p-3 w-full  font-semibold outline-none  bg-gray-50 border-2 border-gray-200 rounded-md"
              ></input>
            </div>
            <div className="flex justify-start">
              <button
                onClick={handleSubmit}
                className="bg-blue-800 w-32 outline-none rounded-md p-3  mb-4 mt-2 text-white"
              >
                Submit
              </button>
            </div>
          </div>
       
      </div>
    </>
  );
};
