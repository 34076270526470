import React, { useState } from "react";
import { Link } from "react-router-dom";


 export const Menu = (props) => {
    var activeComponent = props.activeComponent
    let [menuState, setMenuState] = useState('invisible sm:visible')

    return(
      <div>
      <div >
      <Link onClick={(e)=>{
        e.preventDefault()
        setMenuState('visible sm:visible')
      }}
       className={`${menuState==='invisible sm:visible'?'':'hidden '} sm:hidden absolute z-20 text-2xl mt-2 p-2`}>
          <i class="fas fa-bars"></i>
      </Link>
      <Link onClick={(e)=>{
        e.preventDefault()
        setMenuState('invisible sm:visible')
      }}
       className={`${menuState==='visible sm:visible'?'':'hidden '} sm:hidden absolute z-20  text-2xl text-white p-2`}>
          <i class="fas fa-times"></i>
      </Link>
      </div>
      <div
      name="leftMenu"
      className={`${menuState} w-auto bg-gradient-to-br to-blue-600 from-blue-800 p-8 flex flex-col  items-center text-white h-screen absolute sm:relative z-10 sm:z-0`}
      > 
      <Link  className=''>
        <div className=" flex justify-center">
          <img src='https://firebasestorage.googleapis.com/v0/b/nuvocliniq-test.appspot.com/o/logo.png?alt=media&token=9d2c0738-e261-4938-aee1-704c897624f1' className="h-16"></img>
        </div> 
        <div className='font-bold hover:text-blue-400 text-2xl my-2'>Nuvocliniq</div>
      </Link>
      <div >
        <Link to="/lab_provider_dashboard">
          <div className={`${activeComponent==='dashboard'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
            
            <i class="fas fa-chart-line"></i>
            <div className="ml-4 w-max">My Dashboard</div>
          </div> 
        </Link>
        <Link to="/lab_provider_bookings">
          <div className={`${activeComponent==='bookings'?'bg-green-600 py-2':''} px-4 rounded-full my-5 text-md flex justify-start items-center`}>
            <i class="far fa-calendar-alt"></i>
            <div className="ml-4">My Bookings</div>
          </div>
        </Link>
        <Link to='/lab_provider_all_tests'>
          <div className={`${activeComponent==='myTests'?'bg-green-600 py-2':''} my-5 px-4  rounded-full text-md flex justify-start items-center`}>
            <i class="far fa-calendar-check"></i>
            <div className="ml-4">My Tests</div>
          </div>
        </Link>
        <Link to='/lab_provider_history' >
          <div className={`${activeComponent==='history'?'bg-green-600 py-2':''} px-4  rounded-full my-5 text-md flex justify-start items-center`}>
            <i class="fas fa-history"></i>
            <div className="ml-4">My History</div>
          </div>
        </Link>
        <Link >
          <div className={`${activeComponent==='newUser'?'bg-green-600 py-2':''} px-4  rounded-full my-5  text-md flex justify-start items-center`}>
            <i class="fas fa-users"></i>
            <div className="ml-4">Feedbacks</div>
          </div>
        </Link>
      </div>
    </div>
    </div>
    )
  }