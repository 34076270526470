import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../../../firebase";

import { useAuth } from "../../../contexts";
import { Menu } from "../menu_lp";
import { LabProviderNav } from "../navbar_lp";

export const LabAllTests = (props) => {
  let [testArray, settestArray] = useState();
  let [last, setLast] = useState();
  let [linkText, setLinkText] = useState("View More");
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [editTest, seteditTest] = useState({
    open: false,
    id: null,
    data: null,
  });

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleDeleteTest = (id) => {
    db.collection("labs")
      .doc(`${loggedIn.currentUser.uid}`)
      .collection("testList")
      .doc(id).delete().then(res => sendToast("Successfully deleted the test.", 'success'))
      .catch(err => sendToast('Something went wrong.', 'error'))
  };

  useEffect(() => {
    const initialCredentials = async () => {
      if (!testArray) {
        const first = db
          .collection("labs")
          .doc(`${loggedIn.currentUser.uid}`)
          .collection("testList")
          .orderBy("priority")
          .limit(10);
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          settestArray(snapshot.docs);
          setLast(lastItem);
        });
      }
    };
    return initialCredentials();
  }, []);

  const updateCredentials = async () => {
    if (testArray) {
      const next = db
        .collection("labs")
        .doc(`${loggedIn.currentUser.uid}`)
        .collection("testList")
        .orderBy("priority")
        .startAfter(last.data().priority)
        .limit(10);
      const snapshot = await next.get();
      if (snapshot.docs.length !== 0) {
        const lastItem = snapshot.docs[snapshot.docs.length - 1];
        settestArray([...testArray, ...snapshot.docs]);
        setLast(lastItem);
        //console.log("next", doctorsArray, last.data());
      } else setLinkText("No More Tests");
    }
  };

  const Addtest = (props) => {
    let [newTest, setnewTest] = useState(false);

    let nameRef = useRef();
    let priceRef = useRef();
    let sampleRef = useRef();
    let priorityRef = useRef();
    let statusRef = useRef();

    const handleNewTest = async (e) => {
      e.preventDefault();
      let name = nameRef.current.value;
      let price = priceRef.current.value;
      let sample = sampleRef.current.value;
      let priority = priorityRef.current.value;
      let status = statusRef.current.value;

      if (!name || !price || !sample || !priority) {
        sendToast("All fields are important.", "error");
      } else if (parseInt(priority) < 0)
        sendToast("Enter valid priority.", "error");
      else if (parseInt(price) < 0) sendToast("Enter valid price.", "error");
      else if (name.trim().length === 0)
        sendToast("Enter valid name.", "error");
      else if (sample.trim().length === 0)
        sendToast("Enter valid sample.", "error");
      else {
        let testRef = await db
          .collection("labs")
          .doc(`${loggedIn.currentUser.uid}`)
          .collection("testList")
          .doc();
        let toAddTest = {
          id: testRef.id,
          priority: parseInt(priority),
          price: parseFloat(price),
          providerId: loggedIn.currentUser.uid,
          name,
          sample,
          status,
        };
        //console.log(toAddTest)
        testRef
          .set(toAddTest)
          .then((res) => {
            sendToast("Successfully added new test.", "success");
            setnewTest(false);
          })
          .catch((err) => sendToast("Something went wrong.", "error"));
      }
    };

    return (
      <div className="flex justify-end">
        <div className=" ">
          <Link
            onClick={(e) => {
              e.preventDefault();
              setnewTest(true);
            }}
            className={` rounded-md space-x-2 text-sm bg-blue-600 hover:bg-blue-700 text-white flex justify-center items-center px-3 mr-5 lg:mx-10 py-2 w-max`}
          >
            <i class="far fa-calendar-plus"></i>
            <div>New test</div>
          </Link>
          <div
            className={`${
              newTest ? "visible" : "hidden"
            } fixed top-32 sm:right-12 right-3 z-10 px-4 bg-white py-3 border-2 border-gray-100 rounded-md shadow-md`}
          >
            <div>
              <div className="my-2">Test name</div>
              <input
                ref={nameRef}
                placeholder="Enter test name"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Price</div>
              <input
                type="number"
                ref={priceRef}
                placeholder="Enter test price"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Priority</div>
              <input
                type="number"
                min="0"
                step="1"
                ref={priorityRef}
                placeholder="Enter test price"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Sample</div>
              <input
                ref={sampleRef}
                placeholder="Enter sample type"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Status</div>
              <select
                ref={statusRef}
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              >
                <option>Active</option>
                <option>Inactive</option>
              </select>
            </div>

            <div className="flex justify-end space-x-3 mt-3 text-sm">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setnewTest(false);
                }}
                className="bg-gray-200 px-2 py-1 text-black rounded"
              >
                Cancel
              </Link>
              <Link
                onClick={handleNewTest}
                className="bg-blue-600 px-2 py-1 text-white rounded"
              >
                Add
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const EditTest = (props) => {
    let nameRef = useRef();
    let priceRef = useRef();
    let sampleRef = useRef();
    let priorityRef = useRef();
    let statusRef = useRef();

    const handleEditTest = async (e) => {
      e.preventDefault();
      let name = nameRef.current.value;
      let price = priceRef.current.value;
      let sample = sampleRef.current.value;
      let priority = priorityRef.current.value;
      let status = statusRef.current.value;

      if (!name || !price || !sample || !priority || !status) {
        sendToast("All fields are important.", "error");
      } else if (parseInt(priority) < 0)
        sendToast("Enter valid priority.", "error");
      else if (parseInt(price) < 0) sendToast("Enter valid price.", "error");
      else if (name.trim().length === 0)
        sendToast("Enter valid name.", "error");
      else if (sample.trim().length === 0)
        sendToast("Enter valid sample.", "error");
      else {
        let toAddTest = {
          priority: parseInt(priority),
          price: parseFloat(price),
          name,
          sample,
          status,
        };
        //console.log(toAddTest)
        db.collection("labs")
          .doc(`${loggedIn.currentUser.uid}`)
          .collection("testList")
          .doc(editTest.id)
          .update(toAddTest)
          .then((res) => {
            sendToast("Successfully updated test details.", "success");
            seteditTest({
              open: false,
              id: null,
              data: null,
            });
          })
          .catch((err) => sendToast("Something went wrong.", "error"));
      }
    };

    return (
      <div className="flex justify-end">
        <div className=" ">
          <div
            className={`${
              editTest.open ? "visible" : "hidden"
            } fixed top-32 sm:right-12 right-3 z-10 px-4 bg-white py-3 border-2 border-gray-100 rounded-md shadow-md`}
          >
            <div>
              <div className="my-2">Test name</div>
              <input
                ref={nameRef}
                defaultValue={editTest.data ? editTest.data.name : ""}
                placeholder="Enter test name"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Price</div>
              <input
                type="number"
                defaultValue={editTest.data ? editTest.data.price : ""}
                ref={priceRef}
                placeholder="Enter test price"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Priority</div>
              <input
                type="number"
                min="0"
                step="1"
                defaultValue={editTest.data ? editTest.data.priority : ""}
                ref={priorityRef}
                placeholder="Enter test price"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Sample</div>
              <input
                defaultValue={editTest.data ? editTest.data.sample : ""}
                ref={sampleRef}
                placeholder="Enter sample type"
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              ></input>
            </div>
            <div>
              <div className="my-2">Status</div>
              <select
                ref={statusRef}
                className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
              >
                <option>Active</option>
                <option>Inactive</option>
              </select>
            </div>

            <div className="flex justify-end space-x-3 mt-3 text-sm">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  seteditTest({
                    open: false,
                    id: null,
                    data: null,
                  });
                }}
                className="bg-gray-200 px-2 py-1 text-black rounded"
              >
                Cancel
              </Link>
              <Link
                onClick={handleEditTest}
                className="bg-blue-600 px-2 py-1 text-white rounded"
              >
                Update
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TestCard = (props) => {
    let item = props.item.data();
    return (
      <div className="rounded-md border-2 border-gray-100 shadow-md m-2 sm:m-3 pb-2">
        <div className="mt-2 flex justify-between mx-3 space-x-10">
          <Link className="flex justify-between items-center w-full">
            <div className=" bg-blue-100 text-blue-600 px-2 py-1 rounded">
              {item.priority}
            </div>
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className={`${
                item.status === "Active" ? " text-green-700" : " text-red-600"
              } lg:w-96 sm:w-72 w-max rounded pt-2  px-4 text-base font-bold`}
            >
              {item.name}
            </div>
            <div className="mr-3 min-w-max bg-blue-100 text-blue-600 px-2 py-1 rounded">
              {item.price} ₹
            </div>
            <div className="mr-3 min-w-max bg-yellow-100 text-yellow-600 px-2 py-1 rounded">
              {item.sample}
            </div>
          </Link>
          <div className="flex items-center space-x-5">
            <Link
              onClick={(e) => {
                e.preventDefault();
                seteditTest({
                  open: true,
                  id: props.item.id,
                  data: item,
                });
              }}
            >
              <img
                className="w-8"
                src="https://firebasestorage.googleapis.com/v0/b/nuvocliniq-test.appspot.com/o/Common%2Fedit.svg?alt=media&token=650204a4-96d4-44c6-882c-ebd97c667a6d"
              ></img>
            </Link>
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleDeleteTest(props.item.id);
              }}
            >
              <img
                className="w-8"
                src="https://firebasestorage.googleapis.com/v0/b/nuvocliniq-test.appspot.com/o/Common%2Fdelete.svg?alt=media&token=b982ff0f-c765-4582-b018-618927ce10fa"
              ></img>
            </Link>
          </div>
        </div>
      </div>
    );
  };
  const TestList = (props) => {
    if (!testArray) return <div></div>;
    else if (testArray)
      return (
        <div className="overflow-hidden overscroll-none">
          {testArray.map((item) => {
            return <TestCard item={item} />;
          })}
        </div>
      );
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        editestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none  bg-white">
        <Menu activeComponent="myTests" />
        <div name="otherThanLeftMenu" className="w-full h-full">
          <LabProviderNav />
          <div className="sm:flex justify-start items-center w-full pr-5">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className="flex items-center text-gray-700  justify-start sm:ml-3 px-2 py-5 sm:p-5"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq-test.web.app/back.svg"
              ></img>
              <div className="mx-4">My tests</div>
            </Link>
          </div>
          <Addtest />
          <EditTest />
          <div name="TestList" className="text-black ml-5 flex justify-start">
            <TestList />
          </div>
          <div className="ml-8 mt-3 mb-5">
            <Link
              className={`${
                linkText === "View More" ? "bg-green-600" : "bg-red-600"
              }  rounded px-3 py-2 w-max text-xs  text-white`}
              onClick={updateCredentials}
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
