import React from "react";
import { DocNav } from "./docNav";
import { Menu } from "../../components/leftMenu";

const EarningsComp = (props) => {
  return (
    <div>
      <div className=" mx-2 sm:mx-10 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
        <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
          <i style={{ fontSize: "16px" }} class="fas fa-chart-line"></i>
        </div>
        <div className="ml-4 text-lg">My Earnings</div>
      </div>
      <div className="w-full h-96 mt-20 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img className="w-56" src="empty.svg"></img>
          <div className="flex justify-center text-base w-max border-2 py-1 px-2 items-center mt-5 text-gray-800">
            Under construction
          </div>
        </div>
      </div>
    </div>
  );
};

export const Earnings = () => {
  return (
    <div className=" min-w-full min-h-screen flex bg-white">
      <Menu activeComponent={'earning'} />
      <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
        <DocNav/>
        <EarningsComp />
      </div>
    </div>
  );
}