import React from "react";
//import { Link } from "react-router-dom";

import { VideoCall } from './videoCall'
import { Prescription } from './prescription'

export const Telemedicine = props => {
    return (
        <div className='w-full h-screen flex flex-col sm:flex-row'>
            <div style={{height: '100%'}} className='sm:w-1/2 bg-gray-800'><VideoCall/></div>
            <div className='sm:w-1/2 mt-20 sm:mt-0 h-screen overflow-y-scroll sm:border-l-2'><Prescription/></div>
        </div>
    )
}