import React, { useState } from "react";
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";

export const ChartBar = (props) => {
  let options = {
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };
  let series = [
    {
      name: "series-1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];

  const activeUsers = { 
    chart: {
      id: "subscribers",
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    grid: {
      show: false
    },
    colors: ["#79f7a3"],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      width: 2.5
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
  
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    tooltip: {
      x: { show: false }
    }
  }

const activeUsersSeries = [
    {
      name: "Subscribers",
      data: [1, 2, 8, 4, 5, 6, 9]
    }
  ]

  return (
    <div className='flex'>
    <Chart
      options={options}
      series={series}
      type="bar"
      width={500}
      height={320}
    />
    <Chart options={activeUsers} width={500} series={activeUsersSeries} type="area" height={350} />
  </div>
  );
};
