import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { db, storage, auth } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../contexts";

export const LabProviderSignup = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [logoFile, setLogoFile] = useState();
  let [bannerFile, setBannerFile] = useState();

  var history = useHistory();
  var storageRef = storage.ref();

  var emailRef = useRef();
  var passRef = useRef();
  var nameRef = useRef();
  var addressRef = useRef();
  var cityRef = useRef();
  var pinRef = useRef();
  var logoRef = useRef();
  var bannerRef = useRef();

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleChangeImage = (e, name) => {
    const image = e.target.files[0]; //.toDataURL("image/jpeg", 0.5);
    if (name === "logo") setLogoFile(image);
    else if (name === "banner") setBannerFile(image);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const reEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let email = emailRef.current.value;
    let password = passRef.current.value;
    let name = nameRef.current.value;
    let city = cityRef.current.value;
    let address = addressRef.current.value;
    let pin = pinRef.current.value;

    if (!email || !name || !city || !address || !pin)
      sendToast("Fields marked with * are important!", "error");
    else if (!reEmail.test(String(email).toLowerCase()))
      sendToast("Enter valid Email", "error");
    // Sign
    else {
      var logoUpload = storageRef.child(`labImages/${"abc"}.jpg`);

      var bannerUpload = storageRef.child(`labImages/${"abcd"}.jpg`);

      auth.createUserWithEmailAndPassword(email, password).then((res) => {
        logoUpload.put(logoFile).then((logoR) => {
          logoUpload.getDownloadURL().then((logo) => {
            bannerUpload.put(bannerFile).then((bannerR) => {
              bannerUpload
                .getDownloadURL()
                .then((banner) => {
                  let newInquiry = {
                    email,
                    password,
                    name,
                    city,
                    address,
                    pin,
                    logo,
                    banner,
                    verify: false,
                    createAt: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  db.collection("labs")
                    .doc(res.user.uid)
                    .set(newInquiry)
                    .then((result) => {
                      res.user
                        .updateProfile({
                          displayName: "lab provider",
                        })
                        .then(() => {
                          sendToast(
                            "Successfully created your account!",
                            "success"
                          );
                          window.location.href='"/lab_provider_dashboard"'
                        });
                    })
                    .catch((err) => console.log(err));
                })
                .catch((error) => {
                  console.log(error.code, error.message);
                  if (error.code === "auth/email-already-in-use")
                    sendToast("Email already in use", "error");
                });
            });
          });
        });
      });
    }
  };
  if (loggedIn.currentUser) {
    history.push("/lab_provider_dashboard");
    return <div></div>;
  } else if (!loggedIn.currentUser)
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="w-screen h-screen flex justify-center overflow-x-hidden pt-5 pb-20">
          <div className="sm:w-1/2 w-full h-auto bg-white sm:p-10 p-5">
            <div className="w-full flex flex-col items-center space-y-5">
              <img
                className="w-32 h-32"
                src="https://firebasestorage.googleapis.com/v0/b/nuvoclinic-ad7c7.appspot.com/o/logo.svg?alt=media&token=9094262f-aa48-4d4f-91fd-db7e1704a83d"
              ></img>
              <div className="sm:text-3xl text-xl font-semibold">
                Signup as Lab Provider
              </div>
            </div>

            <div className="mt-10 w-full">
              <div>
                <p>
                  Lab Name<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your lab name"
                  ref={nameRef}
                  className="my-4 p-3 w-full  bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>
                  Email address<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your email address"
                  ref={emailRef}
                  className="my-4 p-3 w-full bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>
                  Password<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your password"
                  ref={passRef}
                  className="my-4 p-3 w-full bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>
                  Address<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your address"
                  ref={addressRef}
                  className="my-4 p-3 w-full  bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>
                  City<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your city"
                  ref={cityRef}
                  className="my-4 p-3 w-full  bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>
                  Pin code<span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Enter your pin code"
                  ref={pinRef}
                  className="my-4 p-3 w-full  bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p>Logo</p>
                <input
                  type="file"
                  placeholder="Upload logo of your lab"
                  ref={logoRef}
                  onChange={(e) => handleChangeImage(e, "logo")}
                  className="my-4  w-full"
                ></input>
              </div>
              <div>
                <p>Banner</p>
                <input
                  type="file"
                  placeholder="Upload banner of your lab"
                  ref={bannerRef}
                  onChange={(e) => handleChangeImage(e, "banner")}
                  className="my-4 w-full"
                ></input>
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div className="flex justify-start ">
                <span className="text-black">Already a member?</span>
                <Link
                  to="/lab_provider_login"
                  className="text-green-500 ml-2 font-bold"
                >
                  {" "}
                  Sign In
                </Link>
              </div>
              <button
                onClick={handleSubmit}
                className="bg-green-600 w-max rounded-md py-3 px-14  my-3 text-white"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};
