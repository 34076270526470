import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { db } from "../firebase";

export const CampDropDown = props => {
    const campDrop = props.campDrop
    const setCampDrop = props.setCampDrop
    const campArray = props.campArray
  
    return (
      <>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setCampDrop({
                show: campDrop.show ? false : true,
                value: campDrop.value,
              });
            }}
            className="flex justify-between w-max rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none"
            id="options-menu"
            aria-expanded="true"
            aria-haspopup="true"
           >
            {campDrop.value.name}
            <svg
              class="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          className={`example overflow-y-scroll ${ 
            campDrop.show ? "" : "invisible"
          } z-20 absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
            >
          <div className="py-1" role="none">
            {campArray.map((item) => {
              return (
                <Link
                  onClick={async (e) => { 
                    e.preventDefault();
                    await setCampDrop({
                      show: false,
                      value: item,
                    });
                  }}
                  className="block px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
      </>
    );
  }