import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
//import { useAuth } from "../../contexts";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Lottie from "react-lottie";
var loader = require("../../components/nuvoLoader.json");

export const Calendar = (props) => {
	let [loading, setLoading] = useState(true);
	// dateArray for array of dates
	let [dateArray, setDateArray] = useState([]);
	let [activeComp, setActiveComp] = useState("dateList");
	// selectedDate for showing time slots
	let [selectedDate, setSelectedDate] = useState();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loader,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid meet",
		},
	};

	var date = new Date();

	let hr = date.getHours();
	let hrString = date.getHours().toString();
	if (hr >= 12) hrString = (date.getHours() - 12).toString();

	let min = date.getMinutes().toString();

	var timeSlot;
	if (hr < 12)
		timeSlot = parseInt(
			`1${hrString.length === 1 ? "0" + hrString : hrString}${
				min.length === 1 ? "0" + min : min
			}`
		);
	else if (hr >= 12)
		timeSlot = parseInt(
			`2${hrString.length === 1 ? "0" + hrString : hrString}${
				min.length === 1 ? "0" + min : min
			}`
		);
	//console.log(hr, min, timeSlot)

	let dt = date.getDate().toString();
	let month = (date.getMonth() + 1).toString();
	let year = date.getFullYear().toString();
	let newDate = parseInt(
		`${year}${month.length === 2 ? month : "0" + month}${
			dt.length === 2 ? dt : "0" + dt
		}`
	);
	//console.log(newDate, dt, month, year)

	var params = useParams();

	useEffect(() => {
		db.collection("doctors")
			.doc(`${params.docId}`)
			.collection("dates")
			.where("dayInt", ">=", newDate)
			.orderBy("dayInt")
			.onSnapshot(async (snapshot) => {
				let tempArray = [];
				let docsArray = snapshot.docs;
				//console.log(docsArray)
				await docsArray.map(async (item) => {
					let tempObj = {
						dayInt: item.data().dayInt,
						date: item.data().date,
						activeSlot: item.data().isTrue,
						bookedSlot: item.data().isBooked,
						day: item.data().dayName,
						month: item.data().monthName,
					};
					tempArray.push(tempObj);
				});
				setDateArray(tempArray);
				setLoading(false);
			});
	}, []);

	const handleDateClicked = (e, index) => {
		e.preventDefault();
		setSelectedDate(index);
		setActiveComp("slotList");
		setLoading(true);
	};

	const handleSlotClicked = (slotInt, name) => {
		window.location.href = `/book/${params.type}/${params.docId}/${dateArray[selectedDate].dayInt}/${slotInt}/${name}/${params.clinicId}/${params.clinicName}`;
	};

	const SlotList = () => {
		let [slotArray, setSlotArray] = useState([]);

		useEffect(() => {
			if (dateArray[selectedDate].dayInt === newDate) {
				db.collection("doctors")
					.doc(`${params.docId}`)
					.collection("dates")
					.doc(`${dateArray[selectedDate].dayInt}`)
					.collection("slots")
					.where("slotInt", ">", timeSlot)
					.where("status", "==", "true")
					.where(
						"type",
						"array-contains",
						params.type === "clinics" ? "In Clinic" : "Telemedicine"
					)
					.onSnapshot((snap) => {
						setSlotArray(snap.docs);
						setLoading(false);
					});
			} else {
				db.collection("doctors")
					.doc(`${params.docId}`)
					.collection("dates")
					.doc(`${dateArray[selectedDate].dayInt}`)
					.collection("slots")
					//.where('slotInt', '>', timeSlot)
					.where("status", "==", "true")
					.where(
						"type",
						"array-contains",
						params.type === "clinics" ? "In Clinic" : "Telemedicine"
					)
					.onSnapshot((snap) => {
						setSlotArray(snap.docs);
						setLoading(false);
					});
			}
		}, []);

		const SingleSlot = (props) => {
			var item = props.item;

			return (
				<div
					className={`sm:w-72 w-72 rounded-md text-sm  mx-2 my-2 text-green-600 bg-green-100`}
				>
					<Link
						className="flex justify-between px-3 py-2"
						onClick={(e) => {
							e.preventDefault();
							handleSlotClicked(item.data().slotInt, item.data().name);
						}}
					>
						<div>
							<div>slot time</div>
							<div className="border-2 px-2 py-1 rounded-md my-2 border-green-400">
								{item.data().name}
							</div>
							<div className="px-2 py-1 mb-1 rounded-md text-sm border-green-400">
								{item.data().address}
							</div>
						</div>
						<div
							style={{ fontSize: "18px" }}
							className={`flex items-center space-x-4 ${
								item.data().status === "false" ? "invisible" : ""
							}`}
						>
							<i
								className={`${
									item.data().type && item.data().type[0] === "In Clinic"
										? "invisible"
										: ""
								} fas fa-video`}
							></i>
							<i
								className={`${
									item.data().type && item.data().type[0] === "Telemedicine"
										? "invisible"
										: ""
								} fas fa-clinic-medical`}
							></i>
						</div>
					</Link>
				</div>
			);
		};

		if (loading)
			return (
				<div className="flex items-center justify-center w-screen h-screen absolute z-10">
					<div className="flex items-center justify-center h-64 w-64 rounded-full">
						<Lottie options={defaultOptions} height={350} width={350} />
					</div>
				</div>
			);
		else if (slotArray.length === 0 || !slotArray)
			return (
				<>
					<div className="flex items-center mt-5 sm:ml-11 ml-4">
						<Link
							className=" text-gray-700"
							onClick={() => setActiveComp("dateList")}
						>
							<img
								className="w-7 h-7 outline-none"
								src="https://nuvocliniq.in/back.svg"
							></img>
						</Link>
						<div className="ml-4 text-base text-gray-600">
							Date{" "}
							<span className="font-semibold text-green-600">
								{" "}
								{dateArray[selectedDate].date}{" "}
							</span>{" "}
							Time Slots
						</div>
					</div>
					<div className="w-full h-96 mt-20 flex justify-center items-center">
						<div className="flex flex-col items-center">
							<img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
							<div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
								No active slots available
							</div>
						</div>
					</div>
				</>
			);
		else if (slotArray)
			return (
				<div className="sm:p-5  py-5">
					<div className="flex items-center  sm:ml-10 ml-3">
						<Link
							className=" text-gray-700"
							onClick={() => setActiveComp("dateList")}
						>
							<img
								className="w-7 h-7 outline-none"
								src="https://nuvocliniq.in/back.svg"
							></img>
						</Link>
						<div className="ml-4 text-base text-gray-600">
							Date{" "}
							<span className="font-semibold text-green-600">
								{" "}
								{dateArray[selectedDate].date}{" "}
							</span>{" "}
							Time Slots
						</div>
					</div>
					<div className="flex flex-wrap mt-5 sm:ml-10 ml-2">
						{slotArray.map((item, index) => {
							//console.log(item.data())
							if (params.clinicName===item.data().address) return <SingleSlot item={item} />;
							else return null
						})}
					</div>
				</div>
			);
	};

	const DateList = () => {
		if (loading)
			return (
				<div className="flex items-center justify-center w-screen h-screen absolute z-10">
					<div className="flex items-center justify-center h-64 w-64 rounded-full">
						<Lottie options={defaultOptions} height={350} width={350} />
					</div>
				</div>
			);
		else if (dateArray.length === 0 || !dateArray)
			return (
				<div className="w-full h-96 mt-20 flex justify-center items-center">
					<div className="flex flex-col items-center">
						<img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
						<div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
							No active slots available
						</div>
					</div>
				</div>
			);
		return (
			<div className="flex flex-wrap justify-center sm:justify-start px-5">
				{dateArray.map((item, index) => {
					//console.log(`${index}`)
					return (
						<Link
							className="m-3"
							onClick={(e) => {
								e.preventDefault();
								handleDateClicked(e, index);
							}}
						>
							<div className=" sm:w-72 w-72 rounded-md border-2 border-gray-100 flex flex-col items-center justify-between  shadow-md p-3">
								<div className=" font-bold w-24 px-2 py-1 text-base  text-green-600 flex justify-start">
									{item.day}
								</div>
								<div className=" text-base text-black rounded w-max  px-2 py-1 flex justify-start">
									{item.date}
								</div>
							</div>
						</Link>
					);
				})}
			</div>
		);
	};

	if (activeComp === "dateList")
		return (
			<div>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Link
					onClick={(e) => {
						e.preventDefault();
						window.history.go(-1);
					}}
					className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
				>
					<img
						className="w-7 h-7 outline-none"
						src="https://nuvocliniq-test.web.app/back.svg"
					></img>
					<div className="mx-4">Doctor's calendar</div>
				</Link>
				<div className="">
					<DateList />
				</div>
			</div>
		);

	if (activeComp === "slotList" && selectedDate + 1) {
		return <SlotList />;
	} else return <div></div>;
};
