import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../contexts";
import { LabProviderNav } from "./navbar_lp";
import { Menu } from "./menu_lp";

import { WaitingApproval } from "./waiting";
import { ChartBar } from "./charts";

const DashboardComp = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  console.log(loggedIn.currentUser)
  
  if (!loggedIn.currentUser) {
    window.location.href = "/lab_provider_signup";
    return <div></div>;
  } else if (loggedIn.currentUser.displayName !== "lab provider") {
    //window.location.href = "/patientHome";
    return <div></div>;
  } 
  else if (loggedIn.userDetails.verify === true)
    return (
      <WaitingApproval/>
    );
  else if (loggedIn.userDetails.verify === false)
    return (
      <>
        <div className="w-full">
         <ChartBar/>
        </div>
      </>
    );
};

export const LabDashboard = () => {
  return (
    <div className=" min-w-full min-h-screen flex bg-white">
      <Menu activeComponent={"dashboard"} />
      <div
        name="otherThanLeftMenu"
        className="w-full overflow-y-scroll overflow-x-hidden h-screen"
      >
        <LabProviderNav />
        <DashboardComp />
      </div>
    </div>
  );
};
