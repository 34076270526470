import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts";

import { PatientNav } from "../patientNav";

export const PrevConsultations = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [appArray, setAppArray] = useState();

  useEffect(() => {
    const initialCredentials = async () => {
      if (!appArray) {
        const first = db
          .collection("appointment")
          .where("patientId", "==", loggedIn.currentUser.uid)
          .where("status", "==", "Complete");
        //.orderBy("appointInt");
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          setAppArray(snapshot.docs);
          //console.log(snapshot.docs);
        });
      }
    };
    return initialCredentials();
  }, []);

  const AppointmentCard = (props) => {
    var item = props.item.data();
    let [docDetails, setDocDetails] = useState({});

    useEffect(() => { 
      db.collection("doctors")
        .doc(item.doctorId)
        .get()
        .then((res) => {
          setDocDetails(res.data());
        });
    }, []);

    return (
      <Link
        to={`/appointment/${props.item.id}`}
        className="rounded-md border-2 border-gray-100 w-screen sm:w-72 shadow-md m-1 sm:m-3 pb-2"
      >
        <div className="w-full sm:w-72 p-3">
          <div className="sm:flex  w-max sm:mr-5  sm:ml-0">
            
              <img
                className=" w-10 h-10 rounded-full mr-3"
                src={docDetails.img}
              ></img>
            
            <div className="flex flex-col sm:mt-0 mt-2 justify-center w-60">
              <div className="font-semibold w-max max-w-full text-base"> {docDetails.name}</div>
              <div className='text-sm'>{docDetails.specialist}</div>
            </div>
          </div>
        </div>
        <div className="flex px-3 my-1">
          <div style={{paddingBottom:'2px', paddingTop:'2px'}} className=" sm:mr-5 mr-2 text-green-700 px-2 bg-green-100 text-sm rounded w-max">
            {item.date}
          </div>
          <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="text-blue-700 w-max rounded  px-2 text-sm bg-blue-100">
            {item.slot}
          </div>
        </div>
        <div className="flex px-3 my-2">
          <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="  max-w-min text-yellow-700 bg-yellow-100 text-sm rounded  px-2 flex justify-center">
            {item.status}
          </div>
          <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="mx-6  w-max text-indigo-700 bg-indigo-100 text-sm rounded  px-2 flex justify-center">
            {item.type}
          </div>
        </div>
      </Link>
    );
  };

  const AppointmentList = (props) => {
    if (!appArray || appArray.length === 0)
      return (
        <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-52" src="empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center rounded-lg text-sm mt-5 text-black">
              Consultation history not available
            </div>
          </div>
        </div>
      );
    else if (appArray)
      return (
        <div className="flex flex-wrap justify-start mt-3 sm:px-8 px-2 py-2">
          {appArray.map((item) => {
            return <AppointmentCard item={item} />;
          })}
        </div>
      );
  };

  return (
    <div>
      <PatientNav />
      <div className=" sm:my-10 my-5">
        <div>
          <div className="mx-3 sm:mx-11 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
            <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
              <i style={{fontSize:'16px'}} class="fas fa-history"></i>
            </div>
            <div className="ml-4 text-lg">My Consultations</div>
          </div>
        </div>
        <div>
          <AppointmentList />
        </div>
      </div>
    </div>
  );
};
