import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Appointments } from "../doctor/docAppointments";
import { db } from "../../firebase";

// import { DocNav } from "../docNav";
// import { Menu } from "../../../components/leftMenu"

const SearchUserComp = () => {
  var a;
  var numberRef = useRef();

  const handleSearchClick = (e) => {
    e.preventDefault();
    let num = numberRef.current.value;
    //setNumber(num)
    if (num.length !== 10) a = 2;
    //sendToast("Enter 10 digits number!", "info");
    else {
      db.collection("patients")
        .where("number", "==", `+91${num}`)
        .get()
        .then((docArr) => {
          if (docArr.size === 0) {
            db.collection("tempUsers")
              .where("number", "==", `+91${num}`)
              .get()
              .then((res) => {
                if (res.size !== 0) {
                  window.location.href = `/consultation/${
                    res.docs[0].id
                  }/${"tempUsers"}`;
                } else {
                  window.location.href = `/addUser/${num}`;
                }
              });
          } else {
            window.location.href = `/consultation/${
              docArr.docs[0].id
            }/${"patients"}`;
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="w-full mx-10 mt-5">
      <div className="text-sm text-black">
        Phone Number <span className="text-red-700">*</span>
      </div>
      <div className="sm:flex sm:w-8/12 w-full mt-3">
        <div className="flex text-sm">
          <div className="border-2 rounded-md px-2 py-2 mr-2 h-10">+91</div>
          <input
            ref={numberRef}
            //defaultValue={user.number}
            className="rounded-md outline-none mb-5 px-2 py-2 border-2 w-full"
            placeholder="Enter phone number"
          ></input>
        </div>
        <button
          onClick={handleSearchClick}
          className="bg-blue-500 hover:bg-blue-800 hover:border-blue-800 text-white rounded border-2 border-blue-500 text-sm sm:ml-2 h-9 px-3"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export const SearchUserNurse = () => {
  let [appArray, setAppArray] = useState();
  let [currentComp, setCurrentComp] = useState("appoList");
  let [currentItem, setCurrentItem] = useState();

  var history = useHistory(); 
  var params = useParams()
  var feeRef = useRef()
  var descriptionRef = useRef()
  var timeRef = useRef()
  var dateRef = useRef()
  var typeRef = useRef()
  var clinicRef = useRef()
  var addressRef = useRef()

  useEffect(() => {
    const initialCredentials = async () => {
      if (!appArray) {
        const first = db
          .collection("appointment")
          //.where("doctorId", "==", loggedIn.currentUser.uid)
          //.where("status", "==", "Scheduled")
          .orderBy("appointInt");
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          setAppArray(snapshot.docs);
          //setLoading(false);
          // console.log(snapshot.docs)
        });
      }
    };
    return initialCredentials();
  }, []);


  const AppointmentCard = (props) => {
    var item = props.item;
    let [fetched, setFecthed] = useState();
    useEffect(() => {
      db.collection("patients")
        .doc(item.data().patientId)
        .get()
        .then((res) => {
          let data = res.data();
          setFecthed(data);
        });
    }, []);

    const handleSingleAppointment = (e) => {
      e.preventDefault();
      let data = {
        appId: item.id,
        appo: item.data(),
        patient: fetched,
      };
      //console.log(item.data())
      setCurrentItem(data);
      setCurrentComp("singleAppo");
    };

    if (!fetched) return <div></div>;
    else if (fetched)
      return (
        <Link
          onClick={handleSingleAppointment}
          className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2"
        >
          <div className="w-52 sm:w-72 p-3">
            <div className="sm:flex  w-max sm:mr-5 ml-20 sm:ml-0">
              <img
                className=" w-10 h-10 rounded-full mr-3"
                src={fetched.img ? fetched.img : "man.png"}
              ></img>

              <div className="flex flex-col justify-center w-60 ">
                <div className="font-semibold w-max max-w-full text-base">
                  {fetched.name}
                </div>
                <div className="text-sm">{fetched.number}</div>
              </div>
            </div>
          </div>
          <div className="flex px-3 my-1">
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className=" sm:mr-5 mr-2 text-green-700 px-2 bg-green-100 text-sm rounded w-max"
            >
              {item.data().date}
            </div>
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className="text-blue-700 w-max rounded  px-2 text-sm bg-blue-100"
            >
              {item.data().slot}
            </div>
          </div>
          <div className="flex px-3 my-2">
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className="w-max text-yellow-700 bg-yellow-100 text-sm rounded  px-2 flex justify-center"
            >
              {item.data().status}
            </div>
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className="mx-6  w-max text-indigo-700 bg-indigo-100 text-sm rounded  px-2 flex justify-center"
            >
              {item.data().type}
            </div>
          </div>
        </Link>
      );
  };

  const AppointmentList = (props) => {
    if (!appArray || appArray.length === 0)
      return (
        <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-56" src="empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
              No appointment scheduled
            </div>
          </div>
        </div>
      );
    else if (appArray)
      return (
        <div className="flex flex-wrap justify-start mt-3 sm:px-8 px-2 py-2">
          {appArray.map((item) => {
            if (item.data().status === "Scheduled")
              return <AppointmentCard item={item} />;
            else return null;
          })}
        </div>
      );
  };
//   const handleNewConsultation = (e) => {
//     e.preventDefault()
//     let date = dateRef.current.value
//     let time = timeRef.current.value
//     let fee = feeRef.current.value
//     let des = descriptionRef.current.value
//     let type = typeRef.current.value

//     let paymentRef = db.collection('transaction').doc()
//     console.log(paymentRef.id)

//     let toAddConsult = {
//         doctorId: loggedIn.currentUser.uid,
//         patientId: params.patientId,
//         patientType: params.foundAt==='tempUsers'?false:true,
//         date: date,
//         slot: time,
//         amount: fee,
//         type: type,
//         paymentId: paymentRef.id,
//         desc: des,
//         status: 'Scheduled'
//     }
//     if (type==='In Clinic') toAddConsult.clinicName= clinicRef.current.value
//     else if (type==='Outside') toAddConsult.address= addressRef.current.value
//     let toAddTrns = {
//         amount:fee,
//         createAt: date,
//         patientId: params.patientId,
//         doctorId: loggedIn.currentUser.uid,
//     }
//     db.collection('visits').doc().set(toAddConsult)
//     .then(res => {
//         setNewConsult(false)
//         db.collection('transaction').doc(paymentRef.id).set(toAddTrns)
//         .then(transRes=> console.log('added'))
//     })
// }
  return (
    <div className=" min-w-full min-h-screen flex bg-white">
      {/* <Menu activeComponent={'newUser'} /> */}
      <div
        name="otherThanLeftMenu"
        className="w-full overflow-y-scroll overflow-x-hidden h-screen"
      >
        {/* <DocNav/> */}
        <div className=" mx-2 sm:mx-10 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
          <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
            <i style={{ fontSize: "16px" }} class="fas fa-users"></i>
          </div>
          <div className="ml-4 text-lg">Search user</div>
        </div>
        <SearchUserComp />

        <div
        //className={`${
        //  newConsult ? "visible" : "hidden"
        //} fixed top-32 sm:right-12 right-3 z-10 px-4 bg-white py-3 border-2 border-gray-100 rounded-md shadow-md`}
      className="fixed top-32 ml-10
      z-10 px-4 bg-white py-3 border-2 border-gray-100 rounded-md shadow-md"
      >
        <div>
          <div>Patient's Name</div>
          <input
            ref={dateRef}
            // defaultValue={`${dt.length === 2 ? dt : "0" + dt}/${
            //   month.length === 2 ? month : "0" + month
            // }/${year}`}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>
        <div>
          <div>Number</div>
          <input
            ref={dateRef}
            // defaultValue={`${dt.length === 2 ? dt : "0" + dt}/${
            //   month.length === 2 ? month : "0" + month
            // }/${year}`}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>
        <div>
          <div>Date</div>
          <input
            ref={dateRef}
            // defaultValue={`${dt.length === 2 ? dt : "0" + dt}/${
            //   month.length === 2 ? month : "0" + month
            // }/${year}`}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>
        <div>
          <div className="my-2">Time</div>
          <input
            ref={timeRef}
            //defaultValue={`${date.getHours()}:${date.getMinutes()}`}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>
        <div>
          <div className="my-2">Type</div>
          <select
            ref={typeRef}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
            // onChange={(e) => {
            //   e.preventDefault();
            //   if (typeRef.current.value === "In Clinic") {
            //     setIsOutside(false);
            //     db.collection("clinics")
            //       .get()
            //       .then((res) => {
            //         let tempArray = [];
            //         res.forEach((doc) => {
            //           tempArray.push(doc);
            //         });
            //         setClinicsArray(tempArray);
            //       });
            //   }
            //   if (typeRef.current.value === "Outside") {
            //     setIsOutside(true);
            //   }
            // }}
          >
            <option>Outside </option>
            <option>In Clinic</option>
          </select>
        </div>
        {/* <div //className={`${isOutside ? "hidden" : ""}`}
        >
          <div className="my-2">Clinics</div>
          <select
            ref={clinicRef}
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          >
            {clinicsArray.length > 0
              ? clinicsArray.map((item) => {
                  return <option>{item.data().name}</option>;
                })
              : ""}
          </select>
        </div> */}
        <div //className={`${isOutside ? "" : "hidden"}`}
        >
          <div className="my-2">Address</div>
          <input
            ref={addressRef}
            placeholder="Enter address here"
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>
        {/* <div>
          <div>Fee</div>
          <input
            ref={feeRef}
            placeholder="Enter fee"
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div> */}
        <div>
          <div className="my-2">Description</div>
          <input
            ref={descriptionRef}
            placeholder="Write description"
            className="border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md"
          ></input>
        </div>

        <div className="flex justify-end space-x-3 mt-2 text-sm">
          <Link
            // onClick={(e) => {
            //   e.preventDefault();
            //   setNewConsult(false);
            // }}
            className="bg-gray-200 px-2 py-1 text-black rounded"
          >
            Cancel
          </Link>
          <Link
            //onClick={handleNewConsultation}
            className="bg-blue-600 px-2 py-1 text-white rounded"
          >
            Add
          </Link>
        </div>
      </div>

        <div className=" ml-64">
        <AppointmentList/>
      </div>
      </div>

    </div>
  );
};
