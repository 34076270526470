import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { db } from "../firebase";
import { Navbar } from "../components/navbar";

export const Inactive = () => {
  return (
    <>
      <Navbar />
      <div className=" sm:h-16 h-14"></div>

        <div className="mt-5 ml-5 sm:ml-10">
          <div className="flex items-start ">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className=" text-gray-700"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq.in/back.svg"
              ></img>
            </Link>
            <div className="ml-4 text-base text-gray-600">Form details</div>
          </div>
        </div>
      

      <div className="w-full h-96 mt-20 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
          <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
            Booking is suspended till further notice.
          </div>
        </div>
      </div>
    </>
  );
};

export const Form = () => {
  let [startDate, setStartDate] = useState(new Date());
  let [activeComp, setActiveComp] = useState('form')

  var nameRef = useRef();
  var streetRef = useRef();
  var postalRef = useRef();
  var cityRef = useRef();
  var genderRef = useRef();
  var ageRef = useRef();
  var adharRef = useRef();
  var emailRef = useRef();
  var agreeRef = useRef();
  var numberRef = useRef();
  var pinRef = useRef()

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let date = startDate;
    let name = nameRef.current.value;
    let address = streetRef.current.value + " " + postalRef.current.value;
    let city = cityRef.current.value;
    let pinCode = pinRef.current.value
    let gender = genderRef.current.value;
    let age = ageRef.current.value;
    let adhar = adharRef.current.value;
    let email = emailRef.current.value;
    let number = numberRef.current.value;
    let agree = agreeRef.current.checked;

    const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!agree) {
      sendToast("Terms and conditions not accepted", "error");
    } else if (
      !date ||
      !name ||
      !city ||
      !gender ||
      !address ||
      !age ||
      !adhar ||
      !number
    ) {
      sendToast("All fields marked with * are important", "error");
    } else if (adhar.length !== 12) {
      sendToast("Aadhar number must be of 12 digits", "error");
    } else if (age > 130 || age < 0 || age === 0) {
      sendToast("Age is not valid", "error");
    } else if (number.length !== 10) {
      sendToast("Enter 10 digits Mobile number", "info");
    } else if (email && !reEmail.test(String(email).toLowerCase())) {
      sendToast("Enter valid Email", "info");
    } else {
      let dt = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
      let month =
        date.getMonth() + 1 > 9
          ? `${date.getMonth() + 1}`
          : `0${date.getMonth() + 1}`;
      let newTest = {
        aadhar: adhar,
        address: address,
        age: age,
        city: city,
        pinCode: pinCode,
        date: `${dt}/${month}/${date.getFullYear()}`,
        email: email,
        gender: gender,
        name: name,
        number: "+91" + number,
        createAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      //console.log(newTest);
      db.collection("covidRTPCR")
        .add(newTest)
        .then((res) => {
          sendToast("Successfully submitted your form", "success");
          setActiveComp('submitted')
        })
        .catch((err) => console.log(err));
    }
  };

  const FormSubmitted = () => {
    return (
      <>
        <Navbar />
        <div className=" sm:h-16 h-14"></div>
  
        
          <div className="mt-5 ml-5 sm:ml-10">
            <div className="flex items-start ">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  window.history.go(-1);
                }}
                className=" text-gray-700"
              >
                <img
                  className="w-7 h-7 outline-none"
                  src="https://nuvocliniq.in/back.svg"
                ></img>
              </Link>
              <div className="ml-4 text-base text-gray-600">Form status</div>
            </div>
          </div>
        <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
            your request has been submitted, to check status of Sample collection and reports pls contact: +91 98997 97320
            </div>
          </div>
        </div>
      </>
    );
  };

  if (activeComp==='form')
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Navbar />
      <div className=" sm:h-16 h-14"></div>

      <div className="flex flex-col items-center ">
        <div className="md:w-6/12 m-5">
          <div className="flex items-start ">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className=" text-gray-700"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq.in/back.svg"
              ></img>
            </Link>
            <div className="ml-4 text-base text-gray-600">Form details</div>
          </div>

          <div className=" flex flex-col items-center mt-2 mb-2 md:flex-row md:justify-center">
            <div>
              <img className=" w-32 " src="logo.png"></img>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="m-1 min-w-max text-3xl font-semibold text-gray-700">
                COVID-19 RT PCR
              </div>
              <div className="text-gray-600 font-semibold min-w-max">
                TEST REQUISITION FORM (TRF){" "}
              </div>
            </div>
          </div>

          <div className="sm:mb-10 mb-7 border-b-2 w-full h-1 border-black-800"></div>

          <div className="space-y-5">
            <div name="date">
              <div>
                Select sample collection date{" "}
                <span className="text-red-600">*</span>
              </div>
              <DatePicker
                className="bg-blue-100 w-28 mt-2 px-3 text-blue-700 py-2 rounded-md outline-none"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div name="name">
              <div className="text-base">
                Patient's Name <span className="text-red-600">*</span>
              </div>
              <div className=" ">
                <input
                  ref={nameRef}
                  type="text"
                  className="border-2 border-gray-200 bg-gray-50 mt-2  mr-5  px-3 py-2 rounded-md  outline-none"
                  placeholder="Enter your name"
                ></input>
              </div>
            </div>
            <div>
              <div className="text-base">
                City <span className="text-red-600">*</span>
              </div>
              <select
                ref={cityRef}
                type="dropdown"
                className="border-2 border-gray-200 bg-gray-50 mt-2 rounded-md outline-none  px-3 py-2"
              >
                <option>Bhopal</option>
                <option>New Delhi</option>
              </select>
            </div>
            <div name="address" className="flex flex-col ">
              <div className="text-base">
                Sample Collection Address{" "}
                <span className="text-red-600">*</span>
              </div>
              <input
                ref={pinRef}
                type="text"
                className="border-2 border-gray-200 w-44 bg-gray-50 mt-2  px-3 py-2 rounded-md  outline-none"
                placeholder="Enter your pin code"
              ></input>
              <input
                ref={streetRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2  px-3 py-2 rounded-md  outline-none"
                placeholder="Enter your address"
              ></input>
              <input
                ref={postalRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2  px-3 py-2 rounded-md  outline-none"
                placeholder="Nearby Landmark"
              ></input>
            </div>
            
            <div className="my-5">
              <div className="text-base">
                Patient's Gender <span className="text-red-600">*</span>
              </div>
              <select
                ref={genderRef}
                type="dropdown"
                className="border-2 border-gray-200 bg-gray-50 px-3 py-2 mt-2 rounded-md  outline-none"
              >
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
            <div name="age">
              <div className="text-base">
                Patient's Age <span className="text-red-600">*</span>
              </div>
              <input
                placeholder="Enter your age"
                ref={ageRef}
                type="number"
                className="border-2 border-gray-200 bg-gray-50 w-min  px-3 py-2 mt-2 rounded-md outline-none"
              ></input>
            </div>
            <div name="adhar" className="my-5 ">
              <div className="text-base">
                Aadhar Number <span className="text-red-600">*</span>
              </div>
              <input
                placeholder="Enter aadhar number"
                ref={adharRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2 px-3 py-2 rounded-md outline-none"
              ></input>
            </div>
            <div name="aadhar" className="my-5">
              <div className="text-base">
                Mobile Number <span className="text-red-600">*</span>
              </div>
              <input
                placeholder="Enter 10 digit number"
                ref={numberRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2 px-3 py-2 rounded-md outline-none"
              ></input>
            </div>
            <div name="email">
              <div className="text-base">Email </div>
              <input
                placeholder="Enter your email"
                ref={emailRef}
                type="text"
                className="border-2 border-gray-200 bg-gray-50 mt-2  px-3 py-2 rounded outline-none"
              ></input>
            </div>

            <div className="border-2 border-gray-200 p-5 rounded-md text-base">
              <div className="mb-2 flex">
                <input
                  ref={agreeRef}
                  type="checkbox"
                  className="border-2 border-gray-100 mr-2 "
                ></input>
                <div className="font-semibold">I agree</div>
              </div>
              <p className="mb-2">
                1. I agree to pay the testing charges at the time of sample
                collection.
              </p>
              <p>
                2. Nuvocliniq is allowed to use the information provided by me
                in this request form for COVID-19 Test and other necessary
                compliances required by the Government from time to time and as
                applicable.
              </p>
            </div>
            <div className="mb-5">
              <div className=" text-blue-600 bg-blue-100 px-2 py-1 rounded w-min text-sm">
                Note:
              </div>
              <div className="mt-2">
                Once the form is submitted and received at our end, our
                representative shall get in touch with you.
              </div>
            </div>
            <div className="pt-3 pb-7">
              <Link
                onClick={handleSubmit}
                className="bg-green-600 hover:bg-green-700 rounded text-white px-7 py-3"
              >
                Submit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  else if (activeComp==='submitted')
  return (<FormSubmitted />)
};
