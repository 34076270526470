import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../../contexts";
import { PatientNav } from "./patientNav";

import Lottie from "react-lottie";
var loader = require("../../components/nuvoLoader.json");

export const DocList = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [docArray, setDocArray] = useState([]);
  let [loading, setLoading] = useState(true);

  var params = useParams();

  if (!loggedIn.currentUser) window.location.href = "/login";

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  useEffect(() => {
    if (params.type === "clinics") {
      db.collection("doctors")
        .where("clinics", "array-contains", params.clinicId)
        .where("verify", "==", true)
        .get()
        .then((res) => {
          let tempArray = [];
          res.forEach((doc) => {
            tempArray.push(doc);
          });
          setLoading(false);
          setDocArray(tempArray);
        });
    } else if (params.type === "teleconsult")
      db.collection("doctors")
        .where("verify", "==", true)
        .get()
        .then((res) => {
          let tempArray = [];
          res.forEach((doc) => {
            tempArray.push(doc);
          });
          setLoading(false);
          setDocArray(tempArray);
        });
  }, []);

  const DoctorCard = (props) => {
    var item = props.item.data();
    return (
      <Link
        to={`/calendar/${params.type}/${props.item.id}/${params.clinicId}/${params.clinicName}`}
        className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2 "
      >
        <div className=" w-52 sm:w-80">
          <div className="sm:flex  w-max sm:mr-5">
            <div className="p-3 ">
              <img
                className=" w-16 h-16 rounded-full"
                src={item.img ? item.img : "man.png"}
              ></img>
            </div>
            <div className="flex flex-col justify-center px-1">
              <div className="sm:flex justify-between w-full">
                <div className="font-semibold">{item.name}</div>
                <div className="text-green-600 bg-green-100  px-2 rounded-md text-base sm:ml-10">₹{item.fee}</div>
              </div>
              <div className="text-sm">{item.specialist}</div>
            </div>
          </div>
          <div className="px-3 text-sm">{item.profile}</div>
        </div>
      </Link>
    );
  };

  const DoctorList = (props) => {
    if (docArray.length === 0)
      return (
        <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
              No doctor in this city
            </div>
          </div>
        </div>
      );
    else if (docArray)
      return (
        <div className="flex flex-wrap justify-start sm:px-5 px-2 py-2">
          {docArray.map((item) => {
            return <DoctorCard item={item} />;
          })}
        </div>
      );
  };

  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen absolute z-10">
        <div className="flex items-center justify-center h-64 w-64 rounded-full">
          <Lottie options={defaultOptions} height={350} width={350} />
        </div>
      </div>
    );
  else
    return (
      <div>
        <PatientNav />
        <div className=" ">
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.history.go(-1);
            }}
            className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
          >
            <img
              className="w-7 h-7 outline-none"
              src="https://nuvocliniq-test.web.app/back.svg"
            ></img>
            <div className="mx-4">List of doctors</div>
          </Link>
          <div className="-mt-5">
            <DoctorList />
          </div>
        </div>
      </div>
    );
};
