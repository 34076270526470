import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts";
import { Navbar } from "../components/navbar";
import { Footer } from "../components/footer";

export const Signup = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());

  var emailRef = useRef(null);
  var passRef = useRef(null);
  var nameRef = useRef(null);
  var checkboxRef = useRef()
  var history = useHistory();

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(checkboxRef.current.checked)
    const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var email = emailRef.current.value;
    var pass = passRef.current.value;
    var name = nameRef.current.value;

    if (!email || !pass || !name)
      sendToast("Fields marked with * are important!", "info");
    else if (!reEmail.test(String(email).toLowerCase()))
      sendToast("Enter valid Email", "error");
    else if (!checkboxRef.current.checked)
      sendToast('Please accept our privacy policies', 'error')
    else {
      auth
        .createUserWithEmailAndPassword(email, pass)
        .then((res) => {
          db.collection("doctors")
            .doc(res.user.uid)
            .set({
              name: name,
              email: email,
              delay: "On time",
              hostStatus: "00",
              verify: false,
            })
            .then((result) => {
              res.user
                .updateProfile({
                  displayName: "doctor",
                })
                .then(() => {
                  sendToast("Successfully created your account!", "success");
                  history.push("/docDetails");
                });
            })
            .catch((err) => console.log(err));
        })
        .catch((error) => {
          console.log(error.code, error.message);
          if (error.code === "auth/email-already-in-use")
            sendToast("Email already in use", "error");
        });
    }
  };
  if (loggedIn.currentUser) {
    history.push("/");
    return <div></div>;
  } else if (!loggedIn.currentUser)
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar />
        <div className=" sm:h-16 h-32"></div>
        <div
          style={{
            backgroundImage: "url(/bg1.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
          className="md:flex md:justify-between sm:pt-14 pt-14 pb-7"
         >
          <div className="hidden sm:visible md:w-6/12 h-5/6 md:flex justify-center items-center">
            <div className="p-2">
              <img className="p-20" src="./doctor-login.png"></img>
            </div>
          </div>
          <div className="flex justify-center items-center md:w-6/12 mx-5 sm:mx-0 sm:pt-0 h-5/6">
            <div className="bg-white p-5 w-full sm:mx-14 rounded-md">
              <div>
                <div className="text-black text-2xl font-semibold pb-5 flex justify-start">
                  Doctor Signup
                </div>
              </div>
              <div className="">
                <div className="items-start">
                  Name <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  placeholder="Enter your name"
                  ref={nameRef}
                  className="my-4 p-3 outline-none w-full border-2 border-gray-200 rounded-md"
                ></input>
              </div>
              <div>
                <p className=" items-start">
                  Email <span className="ml-1 text-red-700">*</span>
                </p>
                <input
                  placeholder="Enter your email"
                  ref={emailRef}
                  className="my-4 p-3 outline-none w-full border-2 border-gray-200 rounded-md"
                ></input>
                <p className="items-start">
                  Password <span className="text-red-700 ml-1">*</span>
                </p>
                <input
                  placeholder="Enter password"
                  ref={passRef}
                  className="my-4 p-3 outline-none w-full border-2 border-gray-200 rounded-md"
                ></input>
                <div className='flex items-start'>
                  <div className='mt-0.5'>
                    <input ref={checkboxRef} type='checkbox' value='accepted'></input>
                  </div>
                  <div className='ml-2'>
                    I have read and accepted <Link to="/privacyPolicies" className='text-blue-600'>privacy policy</Link> of Nuvocliniq.
                  </div>
                </div>
              </div>

              <div className="sm:flex justify-between items-center">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-800 rounded-md p-3 mt-2 mb-4 text-white"
                >
                  Create Account
                </button>
                <div className="flex justify-start">
                  <span className="text-black">Already a member?</span>
                  <Link to="/signin" className="text-green-500 ml-2 font-bold">
                    {" "}
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};
