import React from "react";
import { Link } from "react-router-dom";

import { PatientNav } from "./patientNav";

const BuyMedicineComp = (props) => {
  return (
    <div>
       <Link
          onClick={(e) => {
            e.preventDefault();
            window.history.go(-1);
          }}
          className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
        >
          <img
            className="w-7 h-7 outline-none"
            src="https://nuvocliniq-test.web.app/back.svg"
          ></img>
          <div className="mx-4">Buy medicine</div>
        </Link>
      <div className="w-full h-96 mt-20 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img className="w-56" src="empty.svg"></img>
          <div className="flex justify-center text-base w-max border-2 py-1 px-2 items-center mt-5 text-gray-800">
            Under construction
          </div>
        </div>
      </div>
    </div>
  );
};

export const BuyMedicine = () => {
  return (
    <div className=" min-w-full min-h-screen bg-white">
      <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
        <PatientNav/>
        <BuyMedicineComp />
      </div>
    </div>
  );
}