import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebase";

import { DocNav } from "../docNav";
import { Menu } from "../../../components/leftMenu"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


  const AddComp = () => {

    var params = useParams()
      
    var nameRef = useRef();
    var addressRef = useRef();
    var cityRef = useRef();
    var genderRef = useRef();
    var ageRef = useRef();
    var heightRef = useRef();
    var weightRef = useRef();
    var emailRef = useRef();
    var aadharRef = useRef();

    const handleAddClick = (e) => {
        e.preventDefault()
        let name = nameRef.current.value
        if (!name || name.length<3) sendToast('Enter valid name', 'error')
        else {
            let toAdd = {
                name: name,
                number: `+91${params.number}`,
                address: addressRef.current.value,
                city: cityRef.current.value,
                gender: genderRef.current.value,
                age: ageRef.current.value,
                height: heightRef.current.value,
                weight: weightRef.current.value,
                email: emailRef.current.value,
                aadhar: aadharRef.current.value
            }
            let userRef = db.collection('tempUsers').doc()
            userRef.set(toAdd)
            .then(res => {
                sendToast('Successfully added user', 'success')
                window.location.href=`/consultation/${userRef.id}/${'tempUsers'}`
            })
        }
    }

    const sendToast = (message, type) => {
        const tostMsg = {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };
        if (type === "error") toast.error(message, tostMsg);
        else if (type === "info") toast.info(message, tostMsg);
        else if (type === "success") toast.success(message, tostMsg);
      };

    return (
        <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <div className="px-10 pb-10">
        <div className="flex items-center mt-5 space-x-3">
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.location.href='/searchUser'
            }}
          >
            <img className="w-7 h-7" src="https://nuvocliniq-test.web.app/back.svg"></img>
          </Link>
          <div className=" text-base">Add new user</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-blue-100"></div>
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Name <span className='text-red-600'>*</span></div>
              <input
                className={` bg-gray-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's name"
                ref={nameRef}
              >
              </input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Number</div>
              <div 
                className="rounded-md outline-none w-max text-base px-2 py-1 bg-gray-100 mt-2 mb-5"
                >{`+91${params.number}`}
              </div>
            </div>

            <div className="w-full">
              <div className="text-gray-500 text-sm">Email Address</div>
              <input
                className={`bg-gray-100 outline-none rounded-md text-base px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's email"
                ref={emailRef}
              >
              </input>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Address</div>
              <div className="pr-5">
                <input
                  className={`bg-gray-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  placeholder="Enter patient's address"
                  ref={addressRef}
                 >
                </input>
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Weight (in kilograms)</div>
              <input
                type="number"
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's weight"
                ref={weightRef}
               >
              </input>
            </div>
          </div>
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className="text-gray-500 text-sm">City</div>
              <input
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's city"
                ref={cityRef}
               >
              </input>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Gender</div>
              <select
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                ref={genderRef}
               >
                   <option>Male</option>
                   <option>Female</option>
                   <option>Others</option>
              </select>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Age (in years)</div>
              <input
                type="number"
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's age"
                ref={ageRef}
               >
              </input>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">
                Height (in centimeters)
              </div>
              <input
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's height"
                ref={heightRef}
               >
              </input>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Aadhar Number</div>
              <input
                className={`bg-gray-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's aadhar"
                ref={aadharRef}
              >
              </input>
            </div>
          </div>
        </div>
        <div className="w-full h-0.5 -mt-3 border-t-2 border-blue-100"></div>
        <div className=" flex justify-start mt-5 space-x-3">
          <Link
            onClick={handleAddClick}
            className="rounded hover:bg-green-900 text-base  py-2 px-3 flex justify-center text-white w-36 bg-green-600"
            >
            Add user
          </Link> 
        </div>
      </div>
      </>
    );
  };

  export const Add = () => {
    return (
      <div className=" min-w-full min-h-screen flex bg-white">
        <Menu activeComponent={'newUser'} />
        <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
          <DocNav/>
          <AddComp />
        </div>
      </div>
    );
  }