import React from "react";
import { Link } from "react-router-dom";

export const WaitingApproval = () => {
    return (
      <>
          <div className="mt-5 ml-5 sm:ml-10">
            <div className="flex items-start ">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  window.history.go(-1);
                }}
                className=" text-gray-700"
              >
                <img
                  className="w-7 h-7 outline-none"
                  src="https://nuvocliniq.in/back.svg"
                ></img>
              </Link>
              <div className="ml-4 text-base text-gray-600">Waiting approval</div>
            </div>
          </div>
        
  
        <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
              Nuvocliniq welcomes your proposal for being our lab provider. Your application is being reviewed by our team.
            </div>
          </div>
        </div>
      </>
    );
  }