import React, { useEffect, useRef, useState } from "react";

import { Prescription } from "./prescription";

export const InClinic = props => {

    return(
        <div className='flex justify-center lg:px-20'>
            <div><Prescription/></div>
        </div>
    )
}