import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";

import { DocNav } from "../doctor/docNav";
import { UserMenu } from "../../components/userLeftMenu"

export const DocUserDetails = () => {
    let [user, setUser] = useState({});

    var params = useParams()
    //console.log(params, user)

    useEffect(() => {
        if(params.foundAt === 'patients')
        db.collection("patients")
        .where("number", "==", `+91${params.number}`)
        .get()
        .then((docArr) => {
            setUser(docArr.docs[0].data());
        })
        else if (params.foundAt === 'tempUsers')
        db.collection("tempUsers")
              .doc(`+91${params.number}`)
              .get()
              .then((res) => {
                setUser(res.data());
              });
    }, [])

    return(
        <div className=" min-w-full min-h-screen flex bg-white">
        <UserMenu activeComponent={'newUser'} name={user.name} number={params.number} />
        <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
          <DocNav/>
          <div>
            <div className='flex justify-between items-center p-5'>
                <div className='flex items-center space-x-2'>
                    
                <Link>view</Link>
                </div>
                <Link>exit</Link>
            </div>
        </div>
        </div>
      </div>
    )
}
