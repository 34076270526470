import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { db } from "../../../firebase"; 
import { useAuth } from "../../../contexts";
import { DocNav } from "../docNav";
import { UserMenu } from "../../../components/userLeftMenu";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Lottie from "react-lottie";
var loader = require("../../../components/nuvoLoader.json");

const HistoryComp = () => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [appArray, setAppArray] = useState();
  let [currentComp, setCurrentComp] = useState("appoList");
  let [currentItem, setCurrentItem] = useState();
  let [loading, setLoading] = useState(true);
  let [user, setUser] = useState({})

  var history = useHistory(); 
  var params = useParams()

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };
  useEffect(() => {
    db.collection(`${params.foundAt}`)
      .doc(`${params.patientId}`).get()
      .then((res) => {
        let data = res.data();
        setUser(data);
      });
  }, []);

  useEffect(() => {
    const initialCredentials = async () => {
      if (!appArray) {

        const first = db
          .collection("visits")
          .where("patientId", "==", `${params.patientId}`)
          .where("status", "==", "Complete")
        const docSnap = await first.onSnapshot((snapshot) => {
            
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          setAppArray(snapshot.docs);
          setLoading(false)
          //console.log(snapshot.docs)
        });
      }
    };
    return initialCredentials();
  }, [user]);

  const AppointmentCard = (props) => {
      //console.log('card')
    var item = props.item;
    let [fetched, setFecthed] = useState({});

    useEffect(() => {
        //console.log(props.item.data().patientId);
        db.collection("doctors")
          .doc(item.data().doctorId)
          .get()
          .then((res) => {
            let data = res.data();
            setFecthed(data);
          });
      }, []);
    
    const handleSingleAppointment = (e) => {
      e.preventDefault();
      let data = {
        appId: item.id,
        appo: item.data(),
        doctor: fetched,
      };
      //console.log(item.data())
      setCurrentItem(data);
      setCurrentComp("singleAppo");
    };

    if (!fetched) return <div></div>;
    else if (fetched)
      return (
        <Link
          onClick={handleSingleAppointment}
          className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2"
         >
          <div className="w-52 sm:w-72 p-3">
            <div className="sm:flex  w-max sm:mr-5 ml-20 sm:ml-0">
              
                <img
                  className=" w-10 h-10 rounded-full mr-3"
                  src={fetched.img ? fetched.img : "man.png"}
                ></img>
              
              <div className="flex flex-col justify-center w-60 ">
                <div className="font-semibold w-max max-w-full text-base">{fetched.name}</div>
                <div className='text-sm'>{fetched.number}</div>
              </div>
            </div>
          </div>
          <div className="flex px-3 my-1">
            <div style={{paddingBottom:'2px', paddingTop:'2px'}} className=" sm:mr-5 mr-2 text-green-700 px-2 bg-green-100 text-sm rounded w-max">
              {item.data().date}
            </div>
            <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="text-blue-700 w-max rounded  px-2 text-sm bg-blue-100">
              {item.data().slot}
            </div>
          </div>
          <div className="flex px-3 my-2">
            <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="  max-w-min text-yellow-700 bg-yellow-100 text-sm rounded  px-2 flex justify-center">
              {item.data().status}
            </div>
            <div style={{paddingBottom:'2px', paddingTop:'2px'}} className="mx-6  w-max text-indigo-700 bg-indigo-100 text-sm rounded  px-2 flex justify-center">
              {item.data().type}
            </div>
          </div>
        </Link>
      );
  };
  const AppointmentList = (props) => {
    if (!appArray || appArray.length===0) 
    return (
      <div className="w-full h-96 mt-20 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <img className="w-56" src="https://nuvocliniq.in/empty.svg"></img>
            <div className="flex justify-center w-max border-2 py-1 px-2 items-center text-base mt-5 text-gray-800">
              No Previous consultations
            </div>
          </div>
        </div>
    );
    else if (appArray)
      return (
        <div className="flex flex-wrap justify-start mt-3 sm:px-8 px-2 py-2">
          {appArray.map((item) => {
            if (item.data().status === "Complete")
              return <AppointmentCard item={item} />;
            else return null;
          })}
        </div>
      );
  };

  if (loading)
      return (
        <div className="flex items-center justify-center w-screen h-screen">
          <div className="fixed w-screen h-screen z-50 top-1/2 right-1/2">
            <Lottie options={defaultOptions} height={250} width={250} />
            <div className='text-black'>Please wait</div>
          </div>
        </div>
      );

  else if (!loading && currentComp === "appoList")
    return (
      <div>
        <div>
          <div className="flex justify-between mt-2">

          <div className=" mx-2 sm:mx-10 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
            <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
              <i style={{ fontSize: "16px" }} class="far fa-calendar-alt"></i>
            </div>
            <div className="ml-4 text-lg"> Past consultations</div>
          </div>
        </div>
          <div>
            <AppointmentList />
          </div>
        </div>
      </div>
    );
  else if (!loading && currentComp === "singleAppo")
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="w-full">
          <div className="flex justify-center">
            <div className="flex flex-col items-center sm:w-8/12 lg:w-6/12 sm:shadow-md">
              <Link
                onClick={() => setCurrentComp("appoList")}
                className="flex items-center text-blue-600 w-full justify-start p-5"
              >
                <i class="fas fa-chevron-left font-semibold text-lg"></i>
                <div className="mx-2">Back</div>
              </Link>
              <div name="profilePic" className=" flex mt-14 sm:w-80">
                <div className=" shadow-md rounded-full">
                  <img
                    className="w-28 h-28 rounded-full"
                    src={
                      currentItem.doctor.img
                        ? currentItem.doctor.img
                        : "man.png"
                    }
                  ></img>
                </div>
                <div className="ml-4 mt-2">
                  <div className="font-bold text-xl">
                    {currentItem.doctor.name}
                  </div>
                  <div>{currentItem.doctor.number}</div>
                </div>
              </div>
              <div className="px-3 pb-5 my-5 w-full lg:w-10/12 border-b-2  border-gray-200 flex justify-end"></div>
              <div
                name="profile fields"
                className="sm:flex my-5 justify-around w-full px-5"
              >
                <div className="flex flex-col ">
                  <div className="my-3 ">
                    <div>Patient Name:</div>
                    <div className="my-2 font-semibold text">
                      {currentItem.appo.name}
                    </div>
                  </div>
                  <div className="my-3 ">
                    <div>Fee:</div>
                    <div className="my-2 bg-green-500 text-white font-semibold max-w-min rounded py-0.5 px-2">
                      ₹{currentItem.appo.fee}
                    </div>
                  </div>
                  <div className="my-3 ">
                    <div>Date:</div>
                    <div className="my-2">{currentItem.appo.date}</div>
                  </div>
                  <div className="my-3">
                    <div>Time Slot:</div>
                    <div className="bg-blue-600 font-semibold w-max rounded my-2 px-2 py-0.5 text-white">
                      {currentItem.appo.slot}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="my-3 ">
                    <div>Status:</div>
                    <div className="my-2 max-w-min bg-yellow-500 font-semibold text-white rounded py-0.5 px-2 flex justify-center">
                      {currentItem.appo.status}
                    </div>
                  </div>
                  <div className="my-2.5">
                    <div className="my-2">Type:</div>
                    <div
                      className={`my-2 w-max bg-blue-500 font-semibold text-white rounded py-0.5 px-2 flex justify-center`}
                    >
                      {currentItem.appo.type}
                    </div>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        //console.log(currentItem.patient.number)
                        history.push(`./telemedicine/${currentItem.appId}`);
                      }}
                      className={`${
                        currentItem.appo.type === "In Clinic" ? "hidden" : ""
                      } bg-green-600 text-white rounded px-3 py-1 flex items-center w-max`}
                    >
                      <i class="fas fa-phone-alt"></i>
                      <div className="ml-2 font-semibold text-lg">Call</div>
                    </Link>
                    <Link
                      to={`/prescribe/${currentItem.appId}/${'visits'}`}
                      className={`${
                        currentItem.appo.type === "Telemedicine" ? "hidden" : ""
                      } bg-green-600 text-white rounded px-3 py-1 flex items-center w-max`}
                    >
                      Prescribe
                    </Link>
                  </div>
                  <div className="my-3 ">
                    <div>Payment:</div>
                    <div
                      className={`${
                        currentItem.appo.paymentStatus === "true"
                          ? "text-green-400"
                          : "text-red-500"
                      } font-semibold my-2`}
                    >
                      {currentItem.appo.paymentStatus === "true"
                        ? "Payment done"
                        : "Payment not done yet"}
                    </div>
                    <Link
                      //onClick={handlePaymentDone}
                      className={`${
                        currentItem.appo.paymentStatus === "true"
                          ? "hidden"
                          : ""
                      } my-1 bg-green-500 hover:bg-green-600 px-2 py-1 rounded-md text-white font-semibold`}
                    >
                      Paid
                    </Link>
                  </div>
                  <div className="my-1 ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export const PreviousConsultations = () => {
    return (
      <div className=" min-w-full min-h-screen flex bg-white">
        <UserMenu activeComponent={'history'} />
        <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
          <DocNav/>
          <HistoryComp/>
        </div>
      </div>
    );
  }