import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import axios from "axios";
import { db, storage } from "../../firebase";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Exam extends React.Component {
  constructor(){
    super()
    this.state={
      reportPdfLink:null,
      mobile:undefined,

      exam: {
      name:null,
      age:null,
      married:'Married',
      gender:'Male',
      address:'',
      occupation:null,
      mobile:null,
      value:null,

      pulse:null,
      temp:null,
      rr:null,
      bp1:null,
      bp2:null,
      height:null,
      weight:null,
      bmi:null,
      spo2:null,
      haemoglobin:null,
      randomSugar:null,
      totalCholesterol:null,

      covidInfected: 'No',
      covidFrom: null,
      covidUpto:null,
      vaccinated:null,
      dose1date:null,
      dose2date:null,
      VaccineName:null,

      currentSymptoms:null,
      Addiction:null,
      recentMedic:null,
      pastHistory:null,
      VaccinationInfo:null,

      pallor:'Present',
      cyanosis:'Present',
      ifCyanosis:'Central',
      clubbing:null,
      oedema:null,
      varicoseVein:null,
      lymphNode:'Not Palpable',

      cornea:null,
      visualAbility:null,
      anyDischargeEye:null,

      anyDischargeEar:null,

      hypopigmentation:null,
      rash:null,
      sensation:null,
      wound:null,
      anyOtherSkin:null,

      abdomen:'soft',
      liver:'Not Palpable',
      spleen:'Not Palpable',
      anySpecificDigSys:null,

      nose:null,
      tonsil:null,
      teeth:null,
      tongue:'Moist and clean',

      rrResSys:null,
      airEntry:null,
      wheeze:null,
      crepts:null,
      ronchi:null,

      heartRate:null,
      s1s2:null,
      murmur:null,

      reflexes:null,
      footDrop:'Absent',

      inguna:null,

      anyOther:null
      }

    }
  }

componentDidMount(){
  if (this.props.match.params.action!=='add')
    db.collection('camps').doc(`${this.props.match.params.campId}`).collection('exams')
    .doc(`${this.props.match.params.action}`).get()
    .then(res=>{
      console.log(res.data())
      this.setState({exam:res.data(), mobile:res.data().mobile})
    })
}

handleAddExamClick = async(e) => {
  console.log(this.props.match.params)
  
  await this.setState({exam:{...this.state.exam, mobile:this.state.mobile}})
  console.log(this.state)

  if (this.props.match.params.action==='add') {
  var examRef = await db.collection("camps").doc(`${this.props.match.params.campId}`).collection('exams').doc()
  this.props.match.params.action=examRef.id
  await this.setState({exam:{...this.state.exam,id:examRef.id}})
  examRef.set(this.state.exam)
  .then(res => {
    this.sendToast('Exam added successfully', 'success')
  })
  }

  else {
    db.collection('camps').doc(`${this.props.match.params.campId}`).collection('exams').doc(this.props.match.params.action)
    .update(this.state.exam)
  .then(res => {
    this.sendToast('Exam updated successfully', 'info')
  })
  }
}

handleGenerateReport = async (e) => {
  e.preventDefault();
  //setLoading(true);
  db.collection("camps").doc(`${this.props.match.params.campId}`).collection('exams').doc(this.props.match.params.action)
    .get()
    .then((fres) => {
      if (fres.data()) {
        axios
          .get(
            "https://us-central1-nuvocliniq-test.cloudfunctions.net/onRequestApis/campReportPdf?campId=" +
            `${this.props.match.params.campId}`+'&examId='+`${this.props.match.params.action}`
            //"https://us-central1-nuvoclinic-ad7c7.cloudfunctions.net/onRequestApis/campReportPdf?docId=" +
             // `${`firstcampreport`}`
          )
          .then((res) => {
            db.collection("camps").doc(`${this.props.match.params.campId}`).collection('exams').doc(this.props.match.params.action)
            .update({report:true}).then(resp=> {
              this.sendToast("Report generated successfully!", "success");
              // setLoading(false);
            })
          })
          .catch((err) => console.log(err));
      } else {
        this.sendToast("Please update basic information", "error");
      }
    });
};
handleViewPdf = (e) => {
  e.preventDefault();
  var storageRef = storage.ref();
  // db.collection(`${params.type}`)
  //   .doc(props.appId)
  //   .get()
  //   .then((res) => {
  //     if (!res.data() || !res.data().presCreateAt)
  //       sendToast("Prescription not generated yet", "info");
  //     else
        storageRef
          .child(`campReports/${this.props.match.params.action}.pdf`)
          .getDownloadURL()
          .then((result) => {
            this.setState({reportPdfLink: result});
            document.getElementById("openReportPdf").click();
          //});
    });
};

  sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  render () {
    
    return (
      <div className="px-10 pb-10">
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        <div className="flex items-center mt-5 space-x-3">
          <Link
            to='/camp'
            // onClick={(e) => {
            //   e.preventDefault();
            //   //if (user.number)
            //   //setActiveComp('show')
            //   //else
            //   //setActiveComp("search");
            // }}
          >
            <img
              className="w-7 h-7"
              src="https://nuvocliniq-test.web.app/back.svg"
            ></img>
          </Link>
          <div className='text-lg'>Patient Profile</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-gray-200"></div>
  
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Name</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100  outline-none rounded-md px-2 py-1 mt-2 mb-5 lg:w-8/12`}//lg:w-6/12
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, name:e.target.value}})}
                defaultValue={this.state.exam.name}
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">Marital Status</div>
              <select
                className={`border-gray-200   border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, married:e.target.value}})}
                value={this.state.exam.married}
              >
                <option>Married</option>
                <option>Unmarried</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Address</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, address:e.target.value}})}
                  defaultValue={this.state.exam.address}
                ></textarea>
              </div>
            </div>
            <div className=" w-48 mt-5 sm:mt-0">
              <div className=" text-base">Mobile Number</div>
              {/* <input
                className="rounded-md outline-none w-max text-base px-2 py-1 border-gray-200  border-2 bg-blue-100 mt-2 mb-5"
                onChange={(e)=>this.setState({mobile: e.target.value})}
              ></input> */}
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry='IN'
                value={this.state.mobile}
                //maxLength={11}
                width={'200px'}
                onChange={ mobile => {
                  this.setState({ mobile })
                 
                } }
                //style={{paddingLeft:'5px'}}
              />
            </div>
            
          </div>
  
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className=" text-base">Age (in years)</div>
              <input
                type="number"
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's age"
                onChange={(e)=>this.setState({exam:{...this.state.exam, age:e.target.value}})}
                defaultValue={this.state.exam.age}
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">Gender</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, gender:e.target.value}})}
                defaultValue={this.state.exam.gender}
              >
                <option>Male</option>
                <option>Female</option>
                <option>Others</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Occupation</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, occupation:e.target.value}})}
                  defaultValue={this.state.exam.occupation}
                ></textarea>
              </div>
            </div>
           
          </div>
        </div>
        
        {/* ///////// //////////////////////////////////////////*/}
        <div className="flex items-center mt-5 space-x-3">
          <div className=" text-base">Vitals Information</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-gray-200"></div>
        
         
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
          <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Pulse</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, pulse:e.target.value}})}
                defaultValue={this.state.exam.pulse}
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">SpO2</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, spo2:e.target.value}})}
                defaultValue={this.state.exam.spo2}
              ></input>
            </div>
            
            <div className="w-full">
              <div className=" text-base">Height (in centimeters)</div>
              <input
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's height"
                onChange={(e)=>this.setState({exam:{...this.state.exam, height:e.target.value}})}
                defaultValue={this.state.exam.height}
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">BMI</div>
              <input
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's city"
                onChange={(e)=>this.setState({exam:{...this.state.exam, bmi:e.target.value}})}
                defaultValue={this.state.exam.bmi}
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">Random Sugar</div>
              <input
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's city"
                onChange={(e)=>this.setState({exam:{...this.state.exam, randomSugar:e.target.value}})}
                defaultValue={this.state.exam.randomSugar}
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Respiratory Rate</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, rr:e.target.value}})}
                defaultValue={this.state.exam.rr}
              ></input>
            </div>
   
          </div>
          <div className="sm:w-6/12 w-full">
          <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Blood Pressure</div>
              <div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-20`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, bp1:e.target.value}})}
                defaultValue={this.state.exam.bp1}
              ></input> /{' '} 
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-20`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, bp2:e.target.value}})}
                defaultValue={this.state.exam.bp2}
              ></input>
              </div>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Temperature</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, temp:e.target.value}})}
                defaultValue={this.state.exam.temp}
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">Weight (in kilograms)</div>
              <input
                type="number"
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's weight"
                onChange={(e)=>this.setState({exam:{...this.state.exam, weight:e.target.value}})}
                defaultValue={this.state.exam.weight}
              ></input>
            </div>
  
            <div className="w-full">
              <div className=" text-base">Haemoglobin</div>
              <input
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's city"
                onChange={(e)=>this.setState({exam:{...this.state.exam, haemoglobin:e.target.value}})}
                defaultValue={this.state.exam.haemoglobin}
              ></input>
            </div>
            
            <div className="w-full">
              <div className=" text-base">Total Cholesterol</div>
              <input
                className={`border-gray-200  border-2 bg-blue-100 outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's city"
                onChange={(e)=>this.setState({exam:{...this.state.exam, totalCholesterol:e.target.value}})}
                defaultValue={this.state.exam.totalCholesterol}
              ></input>
            </div>
          </div>
        </div>
        {/* ///////////////////////// /////////////////////////////////////////*/}
         {/* ///////// //////////////////////////////////////////*/}
         <div className="flex items-center mt-5 space-x-3">
          <div className=" text-base">CORONA (COVID19) RELATED INFORMATION</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-gray-200"></div>
        
         
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
           <div className="w-full">
              <div className=" text-base">Got infected?</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, covidInfected:e.target.value}})}
                defaultValue={this.state.exam.covidInfected}
              >
                <option>No</option>
                <option>Yes</option>
              </select>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">If Yes, From</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, covidFrom:e.target.value}})}
                defaultValue={this.state.exam.covidFrom}
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Up to</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, covidUpto:e.target.value}})}
                defaultValue={this.state.exam.covidUpto}
              ></input>
            </div>
            
          </div>
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className=" text-base">Vaccination done</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, vaccinated:e.target.value}})}
                
              >
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Dose 1 date</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, dose1date:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Dose 2 date</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, dose2date:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Name of Vaccine</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, VaccineName:e.target.value}})}
                
              ></input>
            </div>
  
          </div>
        </div>
        {/* ///////////////////////// /////////////////////////////////////////*/}
        {/* ///////// //////////////////////////////////////////*/}
        <div className="flex items-center mt-5 space-x-3">
          <div className=" text-base">PATIENT DETAILS AND EXAMINATION</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-gray-200"></div>
        
         
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className=" text-base">Current Symptoms/Observations</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, currentSymptoms:e.target.value}})}
                  
                ></textarea>
              </div>
            </div>
            <div className="w-full">
              <div className=" text-base">Addiction</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, Addiction:e.target.value}})}
                  
                ></textarea>
              </div>
            </div>
            <div className="w-full">
              <div className=" text-base">Recent/Regular Medication</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, recentMedic:e.target.value}})}
                  
                ></textarea>
              </div>
            </div>
           
            
          </div>
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className=" text-base">Past History and Medication</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, pastHistory:e.target.value}})}
                  
                ></textarea>
              </div>
            </div>
            <div className="w-full">
              <div className=" text-base">Vaccination Information</div>
              <div className="pr-5">
                <textarea
                  className={`border-gray-200  border-2 bg-blue-100 outline-none text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                  //placeholder="Enter patient's address"
                  onChange={(e)=>this.setState({exam:{...this.state.exam, VaccinationInfo:e.target.value}})}
                  
                ></textarea>
              </div>
            </div>
           
          </div>
        </div>
        {/* ///////////////////////// /////////////////////////////////////////*/}
        {/* ///////// //////////////////////////////////////////*/}
        <div className="flex items-center mt-5 space-x-3">
          <div className=" text-base">GENERAL EXAMINATION</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-gray-200"></div>
        
         
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
  
            <div className="w-full">
              <div className=" text-base">Pallor</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, pallor:e.target.value}})}
                
              >
                <option>Present</option>
                <option>Absent</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Cyanosis</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, cyanosis:e.target.value}})}
                
              >
                <option>Present</option>
                <option>Absent</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Cyanosis (if present)</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, ifCyanosis:e.target.value}})}
                
              >
                <option>Central</option>
                <option>Peripheral</option>
              </select>
            </div>
   
          </div>
  
          <div className="sm:w-6/12 w-full">
          <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Clubbing</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, clubbing:e.target.value}})}
                
              ></input>
            </div>
  
           <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Oedema</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, oedema:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Varicose Vein</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, varicoseVein:e.target.value}})}
                
              ></input>
            </div>
  
            <div className="w-full">
              <div className=" text-base">Lymph Nodes</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, lymphNode:e.target.value}})}
                
              >
                <option>Not Palpable</option>
                <option>Palpable</option>
                <option>Cervical</option>
                <option>Axillary</option>
                <option>Groin</option>
                <option>Popliteal</option>
                <option>Other</option>
              </select>
            </div>
            
          </div>
        </div>
        
        {/* *********************************** */}
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            {/* *********************************** */}
            <div>EYE:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Cornea</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, cornea:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Visual Ability (Charts)</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, visualAbility:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Any Discharge, Cornea, Opacity, Visible Cataract, Conjunctiva</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, anyDischargeEye:e.target.value}})}
                
              ></textarea>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>DIGESTIVE SYSTEM:</div>
            <div className="w-full">
              <div className=" text-base">Abdomen</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, abdomen:e.target.value}})}
                
              >
                <option>Soft</option>
                <option>Tender</option>
                <option>Other</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Liver</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, liver:e.target.value}})}
                
              >
                <option>Not Palpable</option>
                <option>Tender</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Spleen</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, spleen:e.target.value}})}
                
              >
                <option>Not Palpable</option>
                <option>Tender</option>
              </select>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Any specific Observation/complaint</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, anySpecificDigSys:e.target.value}})}
                
              ></textarea>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>RESPIRATORY SYSTEM:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Respiration Rate/Minute</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, rrResSys:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Air Entry</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, airEntry:e.target.value}})}
                
              ></textarea>
            </div>
            <div className="w-full">
              <div className=" text-base">Wheeze</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, wheeze:e.target.value}})}
                
              >
                <option>Absent</option>
                <option>Present</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Crepts</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, crepts:e.target.value}})}
                
              >
               <option>Absent</option>
                <option>Present</option>
              </select>
            </div>
            <div className="w-full">
              <div className=" text-base">Ronchi</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, ronchi:e.target.value}})}
                
              >
                <option>Absent</option>
                <option>Present</option>
              </select>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>CARDIO VASCULAR:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Heart Rate/ Minute</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, heartRate:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">S1/S2</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, s1s2:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Murmur/Thrills</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, murmur:e.target.value}})}
                
              ></textarea>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>Any Others:</div>
            <div className="w-full mt-5 sm:mt-0">
              {/* <div className=" text-base"></div> */}
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, anyOther:e.target.value}})}
                
              ></textarea>
            </div>
            
            {/* *********************************** */}
   
          </div>
  
          <div className="sm:w-6/12 w-full">
            {/* *********************************** */}
            <div>EAR:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Any Discharge, Wax, Other</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, anyDischargeEar:e.target.value}})}
                
              ></textarea>
            </div>
            {/* *********************************** */}
            <div>SKIN:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Hypopigmentation/Hyperpigmentation</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, hypopigmentation:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Rash/Itching</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, rash:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Sensation</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, sensation:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Wound/Abrasion/Ulcer</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, wound:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Any other</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, anyOtherSkin:e.target.value}})}
                
              ></input>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>NOSE/MOUTH CHECK:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Nose</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, nose:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Tonsils</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, tonsil:e.target.value}})}
                
              ></input>
            </div><div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Teeth</div>
              <input
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, teeth:e.target.value}})}
                
              ></input>
            </div>
            <div className="w-full">
              <div className=" text-base">Tongue</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, tongue:e.target.value}})}
                
              >
                <option>Moist and clean</option>
                <option>Coated</option>
                <option>Ulcer</option>
              </select>
            </div>
            {/* *********************************** */}
            {/* *********************************** */}
            <div>CENTRAL NERVOUS SYSTEM:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Reflexes / Muscle / Coordination / Gait</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, reflexes:e.target.value}})}
                
              ></textarea>
            </div>
            <div className="w-full">
              <div className=" text-base">Foot Drop</div>
              <select
                className={`border-gray-200  border-2 bg-blue-100  outline-none text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                placeholder="Enter patient's gender"
                onChange={(e)=>this.setState({exam:{...this.state.exam, footDrop:e.target.value}})}
                
              >
                <option>Absent</option>
                <option>Present</option>
              </select>
            </div>
            
            {/* *********************************** */}
            {/* *********************************** */}
            <div>URO GENITAL:</div>
            <div className="w-full mt-5 sm:mt-0">
              <div className=" text-base">Inguna Herina / Hydrocele / Any infection</div>
              <textarea
                className={` border-gray-200  border-2 bg-blue-100 outline-none rounded-md px-2 py-1 mt-2 mb-5 w-max`}
                //placeholder="Enter patient's name"
                onChange={(e)=>this.setState({exam:{...this.state.exam, inguna:e.target.value}})}
                
              ></textarea>
            </div>
            
            {/* *********************************** */}
            
            
          </div>
  
        </div>
        {/* ************************************** */}
        {/* //////////////////////////////////////////////////////////////////*/}
        
         
        
        <div className="w-full h-0.5 -mt-3 border-t-2 border-gray-200"></div>
        <div className=" flex justify-start mt-5 space-x-3">
          <Link
              onClick={this.handleAddExamClick}
            className="rounded hover:bg-green-900 font-semibold text-lg  py-2 px-3 flex justify-center text-white w-36 bg-green-600"
          >
          {this.props.match.params.action==='add'?'Add Exam':'Update'}
          </Link>

          <Link
          onClick={this.handleGenerateReport}
          className={`rounded-md mx-5 py-2 px-3 text-white bg-blue-500 hover:bg-blue-700 font-semibold text-lg`}
        >
          Generate report
        </Link>
        <Link
          onClick={this.handleViewPdf}
          className="rounded-md py-2 px-3 text-white bg-blue-500 hover:bg-blue-700 font-semibold text-lg"
        >
          View Pdf
        </Link>
        <a href={this.state.reportPdfLink} target="_blank" id="openReportPdf"></a>
        </div>
      </div>
    );
  }
  
};

// export const Edit = () => {
//   return (
//     <div className=" min-w-full min-h-screen flex bg-white">
//       <UserMenu activeComponent={"edit"} />
//       <div
//         name="otherThanLeftMenu"
//         className="w-full overflow-y-scroll overflow-x-hidden h-screen"
//       >
//         <DocNav />
//         <EditComp />
//       </div>
//     </div>
//   );
// };

export default withRouter(Exam)