import React, { useRef } from "react";
import { Link } from "react-router-dom";
//import axios from "axios";
//import { useAuth } from "../contexts";
import { Navbar } from "../components/navbar";
import { Footer } from "../components/footer";

export const Home = (props) => {
  return (
    <div>
      <Navbar />
      <div className=" sm:h-16 h-14"></div>

      <div name="" className="w-screen h-full">
        <div
          style={{
            backgroundImage: "url(/bg1.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
          className="pb-14 "
        >
          <div className="lg:px-12 md:px-12 sm:px-10 pb-5 px-0 flex flex-col md:flex-row items-center">
            <div className="md:w-6/12 w-full">
              <div className="w-full sm:flex  items-center">
                <div className=" sm:w-4/12 flex flex-col lg:items-start items-center text-blue-500 pt-5 md:pt-0">
                  <div className="sm:text-3xl text-2xl font-semibold mb-2 md:mb-5 w-max">
                    The most important{" "}
                  </div>
                  <div className="sm:text-3xl text-2xl font-semibold mb-5 md:mb-10 w-max">
                    resolution in{" "}
                    <span className="text-green-700 font-bold">2021</span>
                  </div>
                  <div className="sm:text-3xl text-2xl mb-2 md:mb-5 w-max">
                    Take control of your{" "}
                  </div>
                  <div className="sm:text-3xl text-3xl w-max">
                    health
                  </div>
                </div>
                <div className="sm:w-8/12 px-5 sm:px-3 m mt-5">
                  <img src="image1.png"></img>
                </div>
              </div>
            </div>
            <div className="md:w-6/12 w-full order-first md:order-last">
              <div name="" className="w-full h-96">
                <img
                  className="w-full h-full"
                  src="https://firebasestorage.googleapis.com/v0/b/nuvocliniq-test.appspot.com/o/image%2FMobile_banner.png?alt=media&token=a078c764-deae-4930-b7d3-6e01e0278869"
                ></img>
                <Link
                  to="/homeVisit"
                  style={{ position: "absolute", zIndex: 1 }}
                  className=" animate-pulse mt-2 top-24 sm:top-28 right-2 sm:right-14 bg-green-800 text-white hover:bg-green-200 outline-none rounded-full sm:px-5 px-3 sm:py-2 py-1"
                >
                  Click Here to Book Care@Home
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:px-28 md:px-10 sm:px-8 sm:py-5 px-5 ">
            <div className="flex flex-wrap text-base w-full justify-around">
              <Link
                to="/login"
                className="rounded-full hover:bg-blue-700 flex items-center py-2 px-5 bg-blue-600 text-white  m-5"
              >
                <i class="far fa-calendar-check"></i>
                <div className="ml-2">In Clinic</div>
              </Link>
              <Link
                to="/login"
                className="rounded-full hover:bg-green-700 flex items-center py-2 px-5 bg-green-600 text-white  m-5"
              >
                <i class="fas fa-laptop-medical"></i>
                <div className="ml-2">Teleconsult</div>
              </Link>
              <Link
                to="/login"
                className="rounded-full hover:bg-blue-700 hover:text-white flex items-center py-2 px-5 border-2 border-blue-600 text-blue-600 m-5"
              >
                <i class="fas fa-cart-plus"></i>
                <div className="ml-2">Buy Medicine</div>
              </Link>
              <Link
                to="/login"
                className="rounded-full hover:bg-green-600 hover:text-white flex items-center border-2 py-2 px-5 border-green-600 text-green-600 m-5"
              >
                <i class="fas fa-vial"></i>
                <div className="ml-2">Book Lab Test</div>
              </Link>
              <Link
                to="/form"
                className="rounded-full hover:bg-blue-700 hover:text-white flex items-center py-2 px-5 border-2 border-blue-600 text-blue-600 m-5"
              >
                <i class="fas fa-virus"></i>
                <div className="ml-2">Covid-19 RT-PCR Form</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lg:px-36 md:px-12 sm:px-10 px-5 mt-5 sm:mt-0"
        style={{ backgroundImage: `url(${"home.png"})` }}
      >
        <div className="flex flex-col items-center">
          <div className="sm:flex justify-center text-lg text-black my-5  w-full">
            <div className=" sm:w-6/12 bg-gradient-to-br text-base font-semibold hover:border-opacity-80 space-y-1 rounded-md md:p-8 p-3">
              <div className="font-semibold text-xl md:text-xl  mb-3 ">
                Services
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3 mt-5"
                ></i>{" "}
                Primary healthcare services
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Digital diagnostics
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Expert consultation-
              </div>
              <div style={{ fontSize: "15px" }} className=" pl-9">
                {" "}
                ENT, Cardiology, Chest etc.
              </div>
              <div style={{ fontSize: "15px" }} className="flex items-center">
                <i className="fas fa-check-circle mr-3"></i>
                <div> Teleconsultation, Assisted teleconsult </div>
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Vaccination
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Electronic health data
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                AI based health score
              </div>
            </div>
            <div className="sm:w-6/12 bg-gradient-to-br text-black text-base font-semibold space-y-1 rounded-md md:p-8 p-3 mt-3 sm:mt-0">
              <div className="font-semibold text-xl md:text-xl  mb-3 mt-5 sm:mt-0">
                Pathology Services
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3 mt-5"
                ></i>{" "}
                Blood Glucose
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Complete Urine analysis
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Haemoglobin
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Total Cholesterol
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Bilirubin
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                Fever panel
              </div>
              <div>
                <i
                  style={{ fontSize: "15px" }}
                  className="fas fa-check-circle mr-3"
                ></i>{" "}
                ECG
              </div>
              <div className="flex items-center">
                <i className="fas fa-check-circle mr-3"></i>
                <div> Kidney and Lever Function test</div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" bg-gray-100 text-black sm:py-8 py-5"
        style={{ backgroundImage: `url(${"home.png"})` }}
      >
        <div className="lg:px-36 md:px-12 sm:px-10  px-5 ">
          <div className="sm:flex items-center">
            <div className="sm:w-6/12 px-5">
              <img
                className=" sm:w-9/12 min-h-20  p-5"
                src="patientapp.png"
              ></img>
            </div>
            <div className="lg:ml-20  sm:w-6/12 flex flex-col items-start font-semibold ">
              <div className="mb-6">Mobile Applications</div>
              <a
                href="https://play.google.com/store/apps/details?id=in.nuvocliniq.patient"
                className="sm:text-2xl hover:text-blue-300 text-xl mb-2 md:mb-5 w-max"
              >
                Download Patient App Now
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=in.nuvocliniq.patient"
                className=" py-0.5 hover:text-blue-300 flex items-center text-white bg-black rounded-md px-2 h-10"
              >
                <i class="fab fa-google-play" style={{ fontSize: "20px" }}></i>
                <div className="ml-2">
                  <div
                    className=" text-xs font-light leading-3"
                    style={{ fontSize: "10px" }}
                  >
                    GET IT ON
                  </div>
                  <div className="font-semibold text-sm leading-3">
                    Google Play
                  </div>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=in.nuvocliniq.doctor"
                className="sm:text-2xl text-xl hover:text-blue-300 mb-2 mt-10 md:mb-5 w-max"
              >
                Download Doctor App Now
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=in.nuvocliniq.doctor"
                className=" py-0.5 hover:text-blue-300 flex items-center text-white bg-black rounded-md px-2 h-10"
              >
                <i class="fab fa-google-play" style={{ fontSize: "20px" }}></i>
                <div className="ml-2">
                  <div
                    className=" text-xs font-light leading-3"
                    style={{ fontSize: "10px" }}
                  >
                    GET IT ON
                  </div>
                  <div className="font-semibold text-sm leading-3">
                    Google Play
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
