import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
//import { auth, db, storage } from "../firebase";
//import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts";
import { PatientNav } from "./patientNav";
//import { DoctorDetailsInput } from "./doctor/docDetails";

import { CropperPopup } from "../../components/cropper";

export const UserProfile = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  //console.log(loggedIn.userDetails.profilePic);
  var imageRef = useRef();
  var initialPic = loggedIn.userDetails.img ? loggedIn.userDetails.img : "";
  let [image, setImage] = useState(initialPic);
  let [tempImage, setTempImage] = useState();
  let [popup, setPopup] = useState(false);
  var history = useHistory();

  const handleChangeImage = (e) => {
    const image = e.target.files[0];
    setTempImage(URL.createObjectURL(image));
    setPopup(true);
  };

  if (!loggedIn.currentUser) {
    history.push("/");
    return <div></div>;
  } else if (loggedIn)
    return (
      <div>
        <CropperPopup
          open={popup}
          close={() => setPopup(false)}
          src={tempImage}
          setImage={setImage}
          uid={loggedIn.currentUser.uid}
        />
        <PatientNav />
        <div className="flex justify-start">
          <div className="flex flex-col items-start ml-5 mr-7 sm:mx-10 w-screen sm:w-8/12 lg:w-6/12 ">
            <div className="flex items-start mt-5">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  window.history.go(-1);
                }}
                className=" text-gray-700"
              >
                <img
                  className="w-7 h-7 outline-none"
                  src="https://nuvocliniq.in/back.svg"
                ></img>
              </Link>
              <div className="ml-4 text-base text-gray-600">Profile</div>
            </div>

            <div name="profilePic" className=" flex mt-8 sm:w-80">
              <div className="rounded-full">
                {image !== "" ? (
                  <img className="w-28 h-28 rounded-full" src={image}></img>
                ) : (
                  <div className=" rounded-full text-black">
                    <img style={{height:'120px'}} src='https://nuvocliniq-test.firebaseapp.com/man.png'></img>
                  </div>
                )}
              </div>
              <div className="my-2 z-10 absolute ml-20 ">
                <input
                  ref={imageRef}
                  type="file"
                  onChange={handleChangeImage}
                  style={{ display: "none" }}
                ></input>
                <Link
                  onClick={() => imageRef.current.click()}
                  className="h-8 w-8"
                  style={{ backgroundColor: "#008713" }}
                >
                  <img className="h-8 w-8 " src="edit.svg"></img>
                </Link>
              </div>
              <div className="ml-4 mt-2">
                <div className="font-semibold text-lg">
                  {loggedIn.userDetails.name}
                </div>
                <div>{loggedIn.userDetails.number}</div>
              </div>
            </div>

            <div className="px-3 pb-5 my-5 w-full lg:w-10/12 border-b-2  border-gray-100 flex justify-end">
              <Link
                to="/userInput"
                className="text-sm py-1 bg-blue-700 text-white px-2 rounded"
              >
                Edit Profile
              </Link>
            </div>

						<div
							name="profile fields"
              style={{ fontSize: "15px" }}
							className="flex flex-col space-y-3  sm:w-96 mb-7"
						>
							<div>
								<div>Email Address</div>
								<div className="text-blue-700 bg-blue-100  font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.email}
								</div>
							</div>
							<div>
								<div>Gender</div>
								<div className="text-blue-700 bg-blue-100 font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.gender}
								</div>
							</div>
							<div>
								<div>Age(In Years)</div>
								<div className="text-blue-700 bg-blue-100  font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.age}
								</div>
							</div>
							<div>
								<div>Height(In centimeters)</div>
								<div className="text-blue-700 bg-blue-100  font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.height}
								</div>
							</div>
							<div>
								<div>Weight(In kilograms)</div>
								<div className="text-blue-700 bg-blue-100  font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.weight}
								</div>
							</div>
							<div>
								<div>Address</div>
								<div className="text-blue-700 bg-blue-100  font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.address}
								</div>
							</div>
							<div>
								<div>Aadhar Number</div>
								<div className="text-blue-700 bg-blue-100 font-semibold px-2 py-2 w-max rounded mt-2">
									{loggedIn.userDetails.aadhar}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
};
