import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db, storage } from "../../firebase";
import { useAuth } from "../../contexts";
import Razorpay from "razorpay";
import {PatientNav} from "../patient/patientNav"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PatientSingleAppointment = (props) => {

  let [appDetails, setAppDetails] = useState();
  let [doctorDetails, setdoctorDetails] = useState();
  let [patientDetails, setPatientDetails] = useState()
  let [loggedIn, setLoggedIn] = useState(useAuth());
  let [pdfLink, setPdfLink] = useState();

  var storageRef = storage.ref();
  var params = useParams()
  //console.log(appDetails)

  useEffect(() => {
    const fetched = db
      .collection("appointment")
      .doc(params.appId)
      .get()
      .then((app) => {
        setAppDetails(app.data());

        db.collection("doctors")
          .doc(app.data().doctorId)
          .get()
          .then((doctor) => {
            setdoctorDetails(doctor.data());

            db.collection('patients')
            .doc(app.data().patientId)
            .get()
            .then(patient => {
                setPatientDetails(patient.data())
            })
          });
      });
  }, []);

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleViewPdf = (e) => {
    e.preventDefault();
    //console.log(params.appId)
    db.collection("appointment")
      .doc(params.appId)
      .get()
      .then((res) => {
        if (!res.data() || !res.data().presCreateAt)
          sendToast("Prescription not generated yet", "info");
        else
          storageRef
            .child(`prescriptionPdf/${params.appId}.pdf`)
            .getDownloadURL()
            .then((result) => {
              setPdfLink(result);
              document.getElementById("openPdf").click();
            });
      });
  };

  const handlePay = async (e) => {
    const instance = await new Razorpay({
      key_id: process.env.REACT_APP_RAZOR_PAY_API_ID,
      key_secret: process.env.REACT_APP_RAZOR_PAY_API_SECRET_ID,
    });
    //console.log(instance)
    var orderOption = {
      amount: 100,
      currency: "INR",
      receipt: "order_rcptid_11",
    };
    //secret key  jnSAHgXNUILAsCaSdsRzr8ha
    // key id   rzp_live_vEesTn67BYQuUE
    const order = instance.orders.create(orderOption);
    //console.log(order)
    var options = {
      key: "rzp_live_vEesTn67BYQuUE",
      amount: "100",
      currency: "INR",
      name: patientDetails.name,
      description: "Test Transaction",
      image: patientDetails.img,
      order_id: order.id, //"order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        //console.log(response);
        console.log("payment id", response.razorpay_payment_id);
        var transRef = db.collection("transaction").doc()

        var updateAppoint = {
          paymenType: "Online",
          payment: "Prepaid",
          paymentId: transRef.id,
          paymentStatus: "true",
          transId: response.razorpay_payment_id,
        };

        var newTrans = {
          amount: doctorDetails.fee,
          appointId: params.appId,
          createAt: new Date(),
          number: patientDetails.number,
          status: "true",
          type: "Online",
          transId: response.razorpay_payment_id,
          userId: loggedIn.currentUser.uid
        };

        await db.collection('appointment').doc(params.appId).update(updateAppoint)
        .then(appointRes => {
          db.collection('transaction').doc(transRef.id).set(newTrans)
          .then(transRes => {
            console.log('patment done...')
          })
        })
        
        alert(response.razorpay_payment_id);
        //alert(response.razorpay_order_id);
        //alert(response.razorpay_signature)
      },
      prefill: {
        name: patientDetails.name,
        email: patientDetails.email,
        contact: patientDetails.number,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    console.log("opening..");
    rzp1.open();
  };  

  if (appDetails && doctorDetails)
    return (
      <div className="w-full overflow-hidden overflow-scroll-none">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <PatientNav/>
      
        <div className="flex sm:ml-10">
          <div className="flex flex-col items-center sm:w-8/12 lg:w-6/12">
            <Link
              onClick={(e)=> {
                e.preventDefault()
                window.history.go(-1)
              }}
              className="flex items-center text-gray-700 w-full justify-start p-5"
            >
              <img className="w-7 h-7 outline-none" src="https://nuvocliniq-test.web.app/back.svg"></img>
              <div className="mx-4">Appointment details</div>
            </Link>
            <div name="profilePic" className=" flex mt-14 sm:w-80">
              <div className=" shadow-md rounded-full">
                <img
                  className="w-20 h-20 rounded-full"
                  src={doctorDetails.img?doctorDetails.img:'man.png'}
                ></img>
              </div>
              <div className="ml-4 mt-2">
                <div className="font-semibold">{doctorDetails.name}</div>
                <div className='text-base'>{doctorDetails.specialist}</div>
              </div>
            </div>
            <div className="px-3 pb-5 my-5 w-full lg:w-10/12 border-b-2  border-gray-200 flex justify-end"></div>
            <div
              name="profile fields"
              className="sm:flex my-5 justify-around w-full px-5"
            >
              <div className="flex flex-col ">
                <div className="my-3 ">
                  <div>Patient Name:</div>
                  <div className="my-2 font-semibold text">
                     {appDetails.name}
                  </div>
                </div>
                <div className="my-3 ">
                  <div>Fee:</div>
                  <div className="my-2 bg-green-100 text-green-600 text-base max-w-min rounded py-0.5 px-2">
                    ₹{appDetails.fee}
                  </div>
                </div>
                <div className="my-3 ">
                  <div>Date:</div>
                  <div className="my-2">{appDetails.date}</div>
                </div>
                <div className="my-3">
                  <div>Time Slot:</div>
                  <div className="bg-blue-100 text-blue-600 w-max rounded my-2 px-2 py-0.5">
                    {appDetails.slot}
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="my-3 ">
                  <div>Status:</div>
                  <div className="my-2 max-w-min bg-yellow-100 text-yellow-500 rounded py-0.5 px-2 flex justify-center">
                    {appDetails.status}
                  </div>
                </div>
                <div className="my-2.5">
                  <div className="my-2">Type:</div>
                  <Link className="my-2 w-max bg-blue-100 text-blue-600 text-base rounded py-0.5 px-2 flex justify-center">
                    {appDetails.type}
                  </Link>
                  <Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href= `/patient/teleconsult/${params.appId}/${doctorDetails.name}`
                      }}
                      className={`${
                        appDetails.type === "In Clinic" ? "hidden" : ""
                      } bg-green-600 text-white rounded px-3 py-1 flex items-center w-max`}
                    >
                      <i class="fas fa-phone-alt"></i>
                      <div className="ml-2 font-semibold text-lg">Call</div>
                    </Link>
                </div>
                <div className="my-3 ">
                  <div>Payment:</div>
                  <div
                    className={`${
                      appDetails.paymentStatus === "true"
                        ? "text-green-600"
                        : "text-red-500"
                    } text-base my-2`}
                  >
                    {appDetails.paymentStatus === "true"
                      ? "Payment done"
                      : "Payment not done yet"}
                  </div>
                <button className={`${appDetails.paymentStatus==='true'?'hidden':''} my-1 bg-green-600 hover:bg-green-700 px-3 py-1 rounded-md text-white text-base`}
                  onClick={handlePay} id="rzp-button1">
                  Pay Online
                </button>
                </div>
                <div className={`${appDetails.presCreateAt?'':'hidden'}`}>
                <button className={` bg-blue-500 hover:bg-blue-600 text-white rounded-md px-2 py-1`}
                  onClick={handleViewPdf}
                  >
                  View Prescription
                </button>
                <a href={pdfLink} target="_blank" id="openPdf"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <div></div>;
};
