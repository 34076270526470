import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export const Privacy = () => {

    return (
        <div className='p-5 text-sm'>
            <div>
                <div className='flex items-center '>
                    <img className='w-14 my-2' src='https://firebasestorage.googleapis.com/v0/b/nuvocliniq-test.appspot.com/o/logo.png?alt=media&token=9d2c0738-e261-4938-aee1-704c897624f1'></img>
                    <div>“Nuvocliniq” – A Digital Primary Healthcare Division of Nouveausurge Consulting Pvt Ltd.</div>
                </div>
                <div>Phone +91 755 3552876</div>
                <div>Location - Bhopal, New Delhi</div>
            </div>
            <div className='underline text-2xl font-bold w-screen flex my-5 justify-center'>PRIVACY POLICY</div>
            <div>Nouveausurge Consulting Pvt Ltd and its products/divisions (“Nuvocliniq”) and its wholly and majority-owned entities, keep any and all concerns of its users about privacy at most important factor of their working.  Henceforth and herein, the entities responsible for managing privacy and personal information are referred to and as “NCPL”, “Nuvocliniq”, “we”, “us”. These mentioned references pertain to generally to the entity that obtain, store, process and manage your personal information. </div>
            <div className='my-5'>Further, this Policy applies to the personal information we obtain through “Nuvocliniq” mobile application, websites, portal and social media pages and refers this policy as “Privacy Policy” for all its respective channels of collecting personal information.</div>
            <div>This Privacy Policy defines and describes various types of information obtained by us through channels owned by us and may be through other third-party websites, cookies on how we obtain the information, how we may use that personal information, with whom we may share it, and as applicable, how you may choose to manage the usage by us. The Privacy Policy also describes the measures we take to safeguard the personal information we obtain and how you can contact us in case of any query related to this policy and related practices.</div>
            <div className='my-5'>For details on third-party websites/channels, you may need to go through and review the privacy policies of these third parties to know about their respective practices.</div>
            <div className='text-lg font-light'>PERSONAL INFORMATION GATHERED BY US</div>
            <div className='my-5'>We may obtain personal information through the various Channels. The types of personal information we may obtain include:</div>
            <ul className=' list-disc px-5'>
                <li>contact information (such as name, phone number, email and postal address) for you or for others;</li>
                <li>information obtained and used to create your online account (such as username, password and security question and answer);</li>
                <li>demographic and other information (such as date of birth, age, gender, work/job, marital status and dependents and other family information);</li>
                <li>transactions information;</li>
                <li>payments related information (such as payment modes, including name, billing address and payment details.</li>
                <li>Online location data through app (such as data from your IP Address, Country and Postal code) where we have provided notice and choice, as selected;</li>
            </ul>
            <div className='my-5'>Please note that providing personal information to us is voluntary on your part. However, certain information is needed to be able to provide certain services and products to you and If you choose not to provide us that information, you may not be able to access certain features of our offerings.</div>
            <div>We do not sell or disclose your personal information except as defined at various sections of this Policy and at the time of the collection of any information, or where permissions are taken or otherwise. Where, third parties are involved and information is needed to be share/disclosed, we have tried to safeguard privacy through contractual terms for a restricted usage of personal information shared/disclosed as to perform services on our behalf and as may be needed for meeting legal compliances.</div>
            <div className='my-5'>We shall share the personal information as may be required by applicable law and/or legal process, if such a need arises as in connection with any suspected activity or any investigation by competent government agencies/authorities. However, in any instance of occurrence of such event, we shall seek appropriate permissions from you and/or keep you informed.</div>
            <div>In the event of a sale or transfer of our assets/business or any other such business collaboration/joint venture, we reserve the right to transfer to any information about you to the relevant third-parties.</div>
            <div className='text-lg font-light my-5'>USAGE of Personal Data by NCPL</div>
            <div >The data is particularly used to provide services to you and to continue to enhance our services and user experiences. The data is used particularly for improving information to you, to our doctors, nurses, consultants and other service providers like pathology labs, physiotherapists, etc. for services and financial transactions related to those services.</div>
            <div className='my-5'>The data is also used to personalize your experiences with our systems and services and also to make recommendations including sending promotional communications, advertising, and various offers.</div>
            <div>The data is also used for our operations, including research, workforce management, legal, financial related requirements.</div>
            <div className='text-lg font-light my-5'>HOW WE PROTECT PERSONAL INFORMATION</div>
            <div>We maintain administrative, technical and physical safeguards, consistent with legal requirements where the personal information was obtained, designed to protect against unlawful or unauthorized destruction, loss, alteration, use or disclosure of, or access to, the personal information provided to us through the Channels.</div>
            <div className='text-lg font-light my-5'>COOKIES</div>
            <div>NCPL and its channels like nuvocliniq.in, etc. may place both permanent and temporary cookies in your computer’s hard drive.</div>
            <div className='text-lg font-light my-5'>COMMUNICATION</div>
            <div>We shall user your email address, registered with us to communicate with you on information related to your services and any changes/updates and any new services/promotions announcements. We do not share your email address outside of our channels and third-party services providers, where permission for using your email is taken.</div>
            <div className='text-lg font-light my-5'>DURATION OF RETENDING YOUR PERSONAL INFORMATION</div>
            <div>The personal information obtained from you is retained</div>
                <ul className='my-5 list-disc px-5'>
                    <li>for a duration as needed to provide services to you and maintain a communication with you</li>
                    <li>As required by law</li>
                    <li>And in certain cases, beyond the necessary and lawful period</li>
                </ul>
            <div className='text-lg font-light'>UPDATES/MODIFICATIONS TO THE PRIVACY POLICY</div>
            <div className='my-5'>This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our information practices and/or as may be needed to comply with legal/regulatory requirements</div>
            <div className='text-lg font-light'>CONTACTING US</div>
            <div className='my-5'>If you have any questions or comments about this Privacy Policy, you may contact us by visiting our website <Link>https://nuvocliniq.in</Link></div>   
        </div>
    )
}