import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebase";

import { DocNav } from "../docNav";
import { Menu } from "../../../components/leftMenu"

const SearchUserComp = () => {
    
    var a
    var numberRef = useRef();

    const handleSearchClick = (e) => {
        e.preventDefault();
        let num = numberRef.current.value;
        //setNumber(num)
        if (num.length !== 10) a=2//sendToast("Enter 10 digits number!", "info");
        else {
          db.collection("tempUsers")
                .where("number", "==", `+91${num}`)
                  .get()
                  .then((res) => {
                    if (res.size !== 0) {
                      window.location.href=`/consultation/${res.docs[0].id}/${'tempUsers'}`
                    } else {
                        window.location.href=`/addUser/${num}`
                    }
                  });
          
///////// For now only TempUsers creation and search ///////////////

          // db.collection("patients")
          //   .where("number", "==", `+91${num}`)
          //   .get()
          //   .then((docArr) => {
          //     if (docArr.size === 0) {
          //       db.collection("tempUsers")
          //       .where("number", "==", `+91${num}`)
          //         .get()
          //         .then((res) => {
          //           if (res.size !== 0) {
          //             window.location.href=`/consultation/${res.docs[0].id}/${'tempUsers'}`
          //           } else {
          //               window.location.href=`/addUser/${num}`
          //           }
          //         });
          //     } else {
          //       window.location.href=`/consultation/${docArr.docs[0].id}/${'patients'}`
          //     }
          //   })
          //   .catch((err) => console.log(err));
        }
      };
    return (
      <div className="w-full mx-10 mt-5">
        <div className="text-sm text-black">
          Phone Number <span className="text-red-700">*</span>
        </div>
        <div className="sm:flex sm:w-8/12 w-full mt-3">
          <div className="flex text-sm">
            <div className="border-2 rounded-md px-2 py-2 mr-2 h-10">+91</div>
            <input
              ref={numberRef}
              //defaultValue={user.number}
              className="rounded-md outline-none mb-5 px-2 py-2 border-2 w-full"
              placeholder="Enter phone number"
             ></input>
          </div>
          <button
            onClick={handleSearchClick}
            className="bg-blue-500 hover:bg-blue-800 hover:border-blue-800 text-white rounded border-2 border-blue-500 text-sm sm:ml-2 h-9 px-3"
          >
            Search
          </button>
        </div>
      </div>
    );
  };

  export const SearchUser = () => {
    return (
      <div className=" min-w-full min-h-screen flex bg-white">
        <Menu activeComponent={'newUser'} />
        <div name='otherThanLeftMenu' className='w-full overflow-y-scroll overflow-x-hidden h-screen'>
          <DocNav/>
          <div className=" mx-2 sm:mx-10 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
          <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
            <i style={{ fontSize: "16px" }} class="fas fa-users"></i>
          </div>
          <div className="ml-4 text-lg">Search user</div>
        </div>
          <SearchUserComp />
        </div>
      </div>
    );
  }
  
